import { CardContentContainer } from "./styled";
import fair_launch from "../../../../assets/Home/Card/fair_launch.png";

const CardContent = () => {
  return (
    <CardContentContainer>
      <figure>
        <img src={fair_launch} alt="icon" loading="lazy" />
      </figure>
    </CardContentContainer>
  );
};

export default CardContent;
