import styled from "styled-components";

export const KeyvisualFireflies = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 1vw;
    height: 1vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }
  .firefly::before, .firefly::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw; }
  .firefly::before {
    background: black;
    opacity: 0.2;
    animation: drift ease alternate infinite; }
  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite; }
    .firefly:nth-child(1) {
  animation-name: move1; }
  .firefly:nth-child(1)::before {
    animation-duration: 9s; }
  .firefly:nth-child(1)::after {
    animation-duration: 9s, 5083ms;
    animation-delay: 0ms, 7926ms; }

@keyframes move1 {
  0% {
    transform: translateX(27vw) translateY(-45vh) scale(0.44); }
  3.84615% {
    transform: translateX(46vw) translateY(7vh) scale(0.33); }
  7.69231% {
    transform: translateX(42vw) translateY(42vh) scale(0.57); }
  11.53846% {
    transform: translateX(5vw) translateY(-8vh) scale(0.37); }
  15.38462% {
    transform: translateX(-41vw) translateY(-49vh) scale(0.28); }
  19.23077% {
    transform: translateX(-49vw) translateY(36vh) scale(0.41); }
  23.07692% {
    transform: translateX(2vw) translateY(11vh) scale(0.81); }
  26.92308% {
    transform: translateX(-26vw) translateY(44vh) scale(0.93); }
  30.76923% {
    transform: translateX(49vw) translateY(-37vh) scale(0.59); }
  34.61538% {
    transform: translateX(35vw) translateY(-28vh) scale(0.36); }
  38.46154% {
    transform: translateX(-27vw) translateY(18vh) scale(0.33); }
  42.30769% {
    transform: translateX(-41vw) translateY(50vh) scale(0.88); }
  46.15385% {
    transform: translateX(2vw) translateY(-19vh) scale(0.54); }
  50% {
    transform: translateX(-14vw) translateY(-21vh) scale(0.74); }
  53.84615% {
    transform: translateX(47vw) translateY(-41vh) scale(0.51); }
  57.69231% {
    transform: translateX(-39vw) translateY(17vh) scale(0.34); }
  61.53846% {
    transform: translateX(34vw) translateY(32vh) scale(0.56); }
  65.38462% {
    transform: translateX(11vw) translateY(7vh) scale(0.39); }
  69.23077% {
    transform: translateX(-13vw) translateY(40vh) scale(0.85); }
  73.07692% {
    transform: translateX(25vw) translateY(22vh) scale(0.71); }
  76.92308% {
    transform: translateX(2vw) translateY(15vh) scale(0.73); }
  80.76923% {
    transform: translateX(24vw) translateY(47vh) scale(0.62); }
  84.61538% {
    transform: translateX(-17vw) translateY(-42vh) scale(0.78); }
  88.46154% {
    transform: translateX(20vw) translateY(-6vh) scale(0.8); }
  92.30769% {
    transform: translateX(34vw) translateY(14vh) scale(0.91); }
  96.15385% {
    transform: translateX(-11vw) translateY(-34vh) scale(0.61); }
  100% {
    transform: translateX(-11vw) translateY(-39vh) scale(0.62); } }

.firefly:nth-child(2) {
  animation-name: move2; }
  .firefly:nth-child(2)::before {
    animation-duration: 11s; }
  .firefly:nth-child(2)::after {
    animation-duration: 11s, 9404ms;
    animation-delay: 0ms, 4375ms; }

@keyframes move2 {
  0% {
    transform: translateX(-40vw) translateY(21vh) scale(0.73); }
  3.84615% {
    transform: translateX(-23vw) translateY(24vh) scale(0.99); }
  7.69231% {
    transform: translateX(-17vw) translateY(18vh) scale(0.91); }
  11.53846% {
    transform: translateX(-42vw) translateY(-37vh) scale(0.68); }
  15.38462% {
    transform: translateX(-48vw) translateY(10vh) scale(0.86); }
  19.23077% {
    transform: translateX(6vw) translateY(-23vh) scale(0.66); }
  23.07692% {
    transform: translateX(23vw) translateY(-8vh) scale(0.43); }
  26.92308% {
    transform: translateX(1vw) translateY(-40vh) scale(0.76); }
  30.76923% {
    transform: translateX(40vw) translateY(40vh) scale(0.76); }
  34.61538% {
    transform: translateX(-31vw) translateY(43vh) scale(0.41); }
  38.46154% {
    transform: translateX(-13vw) translateY(23vh) scale(0.52); }
  42.30769% {
    transform: translateX(29vw) translateY(4vh) scale(0.93); }
  46.15385% {
    transform: translateX(-36vw) translateY(31vh) scale(0.44); }
  50% {
    transform: translateX(-15vw) translateY(8vh) scale(0.66); }
  53.84615% {
    transform: translateX(21vw) translateY(-49vh) scale(0.3); }
  57.69231% {
    transform: translateX(9vw) translateY(-14vh) scale(0.71); }
  61.53846% {
    transform: translateX(-1vw) translateY(46vh) scale(0.96); }
  65.38462% {
    transform: translateX(-44vw) translateY(8vh) scale(0.34); }
  69.23077% {
    transform: translateX(27vw) translateY(8vh) scale(0.45); }
  73.07692% {
    transform: translateX(-2vw) translateY(-3vh) scale(0.34); }
  76.92308% {
    transform: translateX(8vw) translateY(-43vh) scale(0.75); }
  80.76923% {
    transform: translateX(2vw) translateY(12vh) scale(0.34); }
  84.61538% {
    transform: translateX(19vw) translateY(5vh) scale(0.33); }
  88.46154% {
    transform: translateX(4vw) translateY(-33vh) scale(0.71); }
  92.30769% {
    transform: translateX(34vw) translateY(-14vh) scale(0.35); }
  96.15385% {
    transform: translateX(33vw) translateY(8vh) scale(0.48); }
  100% {
    transform: translateX(-16vw) translateY(-23vh) scale(0.37); } }

.firefly:nth-child(3) {
  animation-name: move3; }
  .firefly:nth-child(3)::before {
    animation-duration: 12s; }
  .firefly:nth-child(3)::after {
    animation-duration: 12s, 10405ms;
    animation-delay: 0ms, 5818ms; }

@keyframes move3 {
  0% {
    transform: translateX(20vw) translateY(-33vh) scale(0.48); }
  3.57143% {
    transform: translateX(-49vw) translateY(8vh) scale(0.34); }
  7.14286% {
    transform: translateX(1vw) translateY(23vh) scale(0.58); }
  10.71429% {
    transform: translateX(44vw) translateY(-20vh) scale(0.55); }
  14.28571% {
    transform: translateX(-3vw) translateY(5vh) scale(0.3); }
  17.85714% {
    transform: translateX(-17vw) translateY(-44vh) scale(0.76); }
  21.42857% {
    transform: translateX(-29vw) translateY(-20vh) scale(0.6); }
  25% {
    transform: translateX(15vw) translateY(-36vh) scale(0.28); }
  28.57143% {
    transform: translateX(-24vw) translateY(-6vh) scale(0.63); }
  32.14286% {
    transform: translateX(-40vw) translateY(31vh) scale(0.41); }
  35.71429% {
    transform: translateX(-22vw) translateY(33vh) scale(0.92); }
  39.28571% {
    transform: translateX(-45vw) translateY(-7vh) scale(0.96); }
  42.85714% {
    transform: translateX(14vw) translateY(-35vh) scale(0.67); }
  46.42857% {
    transform: translateX(47vw) translateY(13vh) scale(0.56); }
  50% {
    transform: translateX(29vw) translateY(39vh) scale(0.7); }
  53.57143% {
    transform: translateX(50vw) translateY(33vh) scale(0.33); }
  57.14286% {
    transform: translateX(-7vw) translateY(-27vh) scale(0.48); }
  60.71429% {
    transform: translateX(37vw) translateY(17vh) scale(0.6); }
  64.28571% {
    transform: translateX(-2vw) translateY(-27vh) scale(0.4); }
  67.85714% {
    transform: translateX(32vw) translateY(50vh) scale(0.53); }
  71.42857% {
    transform: translateX(41vw) translateY(50vh) scale(0.33); }
  75% {
    transform: translateX(-1vw) translateY(-5vh) scale(0.91); }
  78.57143% {
    transform: translateX(38vw) translateY(-28vh) scale(0.78); }
  82.14286% {
    transform: translateX(-20vw) translateY(-25vh) scale(0.37); }
  85.71429% {
    transform: translateX(-21vw) translateY(44vh) scale(1); }
  89.28571% {
    transform: translateX(42vw) translateY(0vh) scale(0.26); }
  92.85714% {
    transform: translateX(-37vw) translateY(-40vh) scale(0.66); }
  96.42857% {
    transform: translateX(-17vw) translateY(-2vh) scale(0.64); }
  100% {
    transform: translateX(41vw) translateY(34vh) scale(0.96); } }

.firefly:nth-child(4) {
  animation-name: move4; }
  .firefly:nth-child(4)::before {
    animation-duration: 11s; }
  .firefly:nth-child(4)::after {
    animation-duration: 11s, 10813ms;
    animation-delay: 0ms, 7884ms; }

@keyframes move4 {
  0% {
    transform: translateX(-38vw) translateY(40vh) scale(0.91); }
  3.57143% {
    transform: translateX(16vw) translateY(34vh) scale(0.54); }
  7.14286% {
    transform: translateX(22vw) translateY(-45vh) scale(0.44); }
  10.71429% {
    transform: translateX(-43vw) translateY(-37vh) scale(0.68); }
  14.28571% {
    transform: translateX(-13vw) translateY(-29vh) scale(0.39); }
  17.85714% {
    transform: translateX(17vw) translateY(-26vh) scale(0.84); }
  21.42857% {
    transform: translateX(6vw) translateY(-47vh) scale(0.52); }
  25% {
    transform: translateX(2vw) translateY(44vh) scale(0.37); }
  28.57143% {
    transform: translateX(-45vw) translateY(30vh) scale(0.45); }
  32.14286% {
    transform: translateX(36vw) translateY(19vh) scale(0.73); }
  35.71429% {
    transform: translateX(23vw) translateY(-2vh) scale(0.77); }
  39.28571% {
    transform: translateX(-1vw) translateY(11vh) scale(0.36); }
  42.85714% {
    transform: translateX(44vw) translateY(-17vh) scale(1); }
  46.42857% {
    transform: translateX(-42vw) translateY(-36vh) scale(0.49); }
  50% {
    transform: translateX(-41vw) translateY(-17vh) scale(0.51); }
  53.57143% {
    transform: translateX(-9vw) translateY(23vh) scale(0.27); }
  57.14286% {
    transform: translateX(23vw) translateY(28vh) scale(0.85); }
  60.71429% {
    transform: translateX(-46vw) translateY(24vh) scale(0.92); }
  64.28571% {
    transform: translateX(10vw) translateY(50vh) scale(0.46); }
  67.85714% {
    transform: translateX(30vw) translateY(36vh) scale(0.74); }
  71.42857% {
    transform: translateX(40vw) translateY(-36vh) scale(0.33); }
  75% {
    transform: translateX(-30vw) translateY(19vh) scale(0.26); }
  78.57143% {
    transform: translateX(47vw) translateY(50vh) scale(0.44); }
  82.14286% {
    transform: translateX(-33vw) translateY(5vh) scale(0.75); }
  85.71429% {
    transform: translateX(8vw) translateY(38vh) scale(0.36); }
  89.28571% {
    transform: translateX(-25vw) translateY(12vh) scale(0.67); }
  92.85714% {
    transform: translateX(-9vw) translateY(-33vh) scale(0.8); }
  96.42857% {
    transform: translateX(17vw) translateY(-44vh) scale(0.54); }
  100% {
    transform: translateX(6vw) translateY(-26vh) scale(0.93); } }

.firefly:nth-child(5) {
  animation-name: move5; }
  .firefly:nth-child(5)::before {
    animation-duration: 14s; }
  .firefly:nth-child(5)::after {
    animation-duration: 14s, 9335ms;
    animation-delay: 0ms, 3987ms; }

@keyframes move5 {
  0% {
    transform: translateX(50vw) translateY(-4vh) scale(0.37); }
  4.34783% {
    transform: translateX(-46vw) translateY(-3vh) scale(0.43); }
  8.69565% {
    transform: translateX(-27vw) translateY(50vh) scale(0.36); }
  13.04348% {
    transform: translateX(-26vw) translateY(-49vh) scale(0.77); }
  17.3913% {
    transform: translateX(-22vw) translateY(-44vh) scale(0.59); }
  21.73913% {
    transform: translateX(35vw) translateY(-41vh) scale(0.6); }
  26.08696% {
    transform: translateX(-46vw) translateY(30vh) scale(0.97); }
  30.43478% {
    transform: translateX(0vw) translateY(10vh) scale(0.58); }
  34.78261% {
    transform: translateX(39vw) translateY(-21vh) scale(0.73); }
  39.13043% {
    transform: translateX(41vw) translateY(-23vh) scale(0.87); }
  43.47826% {
    transform: translateX(16vw) translateY(39vh) scale(0.74); }
  47.82609% {
    transform: translateX(8vw) translateY(30vh) scale(0.46); }
  52.17391% {
    transform: translateX(7vw) translateY(43vh) scale(0.96); }
  56.52174% {
    transform: translateX(-28vw) translateY(44vh) scale(0.81); }
  60.86957% {
    transform: translateX(19vw) translateY(5vh) scale(0.34); }
  65.21739% {
    transform: translateX(28vw) translateY(-40vh) scale(0.77); }
  69.56522% {
    transform: translateX(30vw) translateY(17vh) scale(0.81); }
  73.91304% {
    transform: translateX(-33vw) translateY(-21vh) scale(0.51); }
  78.26087% {
    transform: translateX(40vw) translateY(26vh) scale(0.3); }
  82.6087% {
    transform: translateX(-46vw) translateY(-20vh) scale(0.61); }
  86.95652% {
    transform: translateX(40vw) translateY(35vh) scale(0.88); }
  91.30435% {
    transform: translateX(-14vw) translateY(-33vh) scale(0.72); }
  95.65217% {
    transform: translateX(-29vw) translateY(33vh) scale(0.82); }
  100% {
    transform: translateX(-39vw) translateY(-45vh) scale(0.63); } }

.firefly:nth-child(6) {
  animation-name: move6; }
  .firefly:nth-child(6)::before {
    animation-duration: 10s; }
  .firefly:nth-child(6)::after {
    animation-duration: 10s, 5076ms;
    animation-delay: 0ms, 2724ms; }

@keyframes move6 {
  0% {
    transform: translateX(39vw) translateY(1vh) scale(0.59); }
  5.55556% {
    transform: translateX(44vw) translateY(-43vh) scale(0.62); }
  11.11111% {
    transform: translateX(20vw) translateY(-28vh) scale(0.49); }
  16.66667% {
    transform: translateX(29vw) translateY(-42vh) scale(0.75); }
  22.22222% {
    transform: translateX(10vw) translateY(10vh) scale(0.79); }
  27.77778% {
    transform: translateX(27vw) translateY(-47vh) scale(0.46); }
  33.33333% {
    transform: translateX(-37vw) translateY(-38vh) scale(0.81); }
  38.88889% {
    transform: translateX(10vw) translateY(44vh) scale(0.99); }
  44.44444% {
    transform: translateX(23vw) translateY(17vh) scale(0.83); }
  50% {
    transform: translateX(16vw) translateY(-22vh) scale(0.72); }
  55.55556% {
    transform: translateX(40vw) translateY(3vh) scale(0.75); }
  61.11111% {
    transform: translateX(49vw) translateY(49vh) scale(0.66); }
  66.66667% {
    transform: translateX(-19vw) translateY(-34vh) scale(0.47); }
  72.22222% {
    transform: translateX(1vw) translateY(27vh) scale(0.67); }
  77.77778% {
    transform: translateX(36vw) translateY(42vh) scale(0.54); }
  83.33333% {
    transform: translateX(49vw) translateY(-41vh) scale(0.45); }
  88.88889% {
    transform: translateX(-21vw) translateY(-48vh) scale(0.57); }
  94.44444% {
    transform: translateX(14vw) translateY(49vh) scale(0.37); }
  100% {
    transform: translateX(-7vw) translateY(39vh) scale(0.49); } }

.firefly:nth-child(7) {
  animation-name: move7; }
  .firefly:nth-child(7)::before {
    animation-duration: 10s; }
  .firefly:nth-child(7)::after {
    animation-duration: 10s, 9431ms;
    animation-delay: 0ms, 2411ms; }

@keyframes move7 {
  0% {
    transform: translateX(48vw) translateY(-37vh) scale(0.59); }
  4% {
    transform: translateX(-24vw) translateY(-10vh) scale(0.69); }
  8% {
    transform: translateX(43vw) translateY(-7vh) scale(0.91); }
  12% {
    transform: translateX(-22vw) translateY(-12vh) scale(0.27); }
  16% {
    transform: translateX(27vw) translateY(-10vh) scale(0.3); }
  20% {
    transform: translateX(-13vw) translateY(-22vh) scale(0.83); }
  24% {
    transform: translateX(-41vw) translateY(48vh) scale(0.98); }
  28% {
    transform: translateX(21vw) translateY(-33vh) scale(0.27); }
  32% {
    transform: translateX(-26vw) translateY(48vh) scale(0.56); }
  36% {
    transform: translateX(-39vw) translateY(25vh) scale(0.84); }
  40% {
    transform: translateX(50vw) translateY(23vh) scale(0.96); }
  44% {
    transform: translateX(18vw) translateY(-15vh) scale(0.94); }
  48% {
    transform: translateX(-31vw) translateY(-14vh) scale(0.47); }
  52% {
    transform: translateX(34vw) translateY(13vh) scale(0.54); }
  56% {
    transform: translateX(24vw) translateY(-32vh) scale(0.99); }
  60% {
    transform: translateX(-48vw) translateY(-37vh) scale(0.4); }
  64% {
    transform: translateX(36vw) translateY(-18vh) scale(0.84); }
  68% {
    transform: translateX(-6vw) translateY(40vh) scale(0.31); }
  72% {
    transform: translateX(-12vw) translateY(-44vh) scale(0.29); }
  76% {
    transform: translateX(-4vw) translateY(-22vh) scale(0.73); }
  80% {
    transform: translateX(-7vw) translateY(-28vh) scale(0.71); }
  84% {
    transform: translateX(-21vw) translateY(-15vh) scale(0.51); }
  88% {
    transform: translateX(45vw) translateY(41vh) scale(0.4); }
  92% {
    transform: translateX(-4vw) translateY(-32vh) scale(0.42); }
  96% {
    transform: translateX(-43vw) translateY(6vh) scale(0.7); }
  100% {
    transform: translateX(6vw) translateY(-13vh) scale(0.82); } }

.firefly:nth-child(8) {
  animation-name: move8; }
  .firefly:nth-child(8)::before {
    animation-duration: 13s; }
  .firefly:nth-child(8)::after {
    animation-duration: 13s, 10917ms;
    animation-delay: 0ms, 6734ms; }

@keyframes move8 {
  0% {
    transform: translateX(12vw) translateY(-39vh) scale(0.28); }
  4% {
    transform: translateX(-12vw) translateY(46vh) scale(0.82); }
  8% {
    transform: translateX(-6vw) translateY(50vh) scale(0.28); }
  12% {
    transform: translateX(-3vw) translateY(0vh) scale(0.92); }
  16% {
    transform: translateX(24vw) translateY(30vh) scale(0.91); }
  20% {
    transform: translateX(-21vw) translateY(-31vh) scale(0.49); }
  24% {
    transform: translateX(48vw) translateY(12vh) scale(0.44); }
  28% {
    transform: translateX(-39vw) translateY(1vh) scale(0.83); }
  32% {
    transform: translateX(-14vw) translateY(-3vh) scale(0.9); }
  36% {
    transform: translateX(38vw) translateY(49vh) scale(0.54); }
  40% {
    transform: translateX(-13vw) translateY(10vh) scale(0.37); }
  44% {
    transform: translateX(-8vw) translateY(50vh) scale(0.41); }
  48% {
    transform: translateX(-37vw) translateY(-6vh) scale(0.75); }
  52% {
    transform: translateX(21vw) translateY(-3vh) scale(0.6); }
  56% {
    transform: translateX(46vw) translateY(4vh) scale(0.95); }
  60% {
    transform: translateX(-11vw) translateY(-48vh) scale(0.41); }
  64% {
    transform: translateX(5vw) translateY(-37vh) scale(0.89); }
  68% {
    transform: translateX(35vw) translateY(-32vh) scale(0.3); }
  72% {
    transform: translateX(41vw) translateY(-5vh) scale(0.8); }
  76% {
    transform: translateX(-27vw) translateY(11vh) scale(0.96); }
  80% {
    transform: translateX(14vw) translateY(-47vh) scale(0.96); }
  84% {
    transform: translateX(50vw) translateY(30vh) scale(0.89); }
  88% {
    transform: translateX(-17vw) translateY(-40vh) scale(0.91); }
  92% {
    transform: translateX(40vw) translateY(-10vh) scale(0.61); }
  96% {
    transform: translateX(-23vw) translateY(21vh) scale(1); }
  100% {
    transform: translateX(-28vw) translateY(40vh) scale(0.81); } }

.firefly:nth-child(9) {
  animation-name: move9; }
  .firefly:nth-child(9)::before {
    animation-duration: 16s; }
  .firefly:nth-child(9)::after {
    animation-duration: 16s, 6431ms;
    animation-delay: 0ms, 2897ms; }

@keyframes move9 {
  0% {
    transform: translateX(-11vw) translateY(-7vh) scale(0.93); }
  4% {
    transform: translateX(14vw) translateY(-48vh) scale(0.38); }
  8% {
    transform: translateX(3vw) translateY(6vh) scale(0.69); }
  12% {
    transform: translateX(14vw) translateY(-12vh) scale(0.64); }
  16% {
    transform: translateX(10vw) translateY(20vh) scale(0.82); }
  20% {
    transform: translateX(34vw) translateY(-45vh) scale(0.92); }
  24% {
    transform: translateX(26vw) translateY(-36vh) scale(0.6); }
  28% {
    transform: translateX(-32vw) translateY(-28vh) scale(0.82); }
  32% {
    transform: translateX(9vw) translateY(-8vh) scale(0.51); }
  36% {
    transform: translateX(-48vw) translateY(-31vh) scale(0.42); }
  40% {
    transform: translateX(-6vw) translateY(-46vh) scale(0.52); }
  44% {
    transform: translateX(25vw) translateY(27vh) scale(0.8); }
  48% {
    transform: translateX(44vw) translateY(36vh) scale(0.92); }
  52% {
    transform: translateX(45vw) translateY(36vh) scale(0.88); }
  56% {
    transform: translateX(-28vw) translateY(-23vh) scale(0.86); }
  60% {
    transform: translateX(2vw) translateY(-16vh) scale(0.88); }
  64% {
    transform: translateX(-16vw) translateY(-3vh) scale(0.26); }
  68% {
    transform: translateX(21vw) translateY(4vh) scale(0.93); }
  72% {
    transform: translateX(15vw) translateY(34vh) scale(0.6); }
  76% {
    transform: translateX(10vw) translateY(43vh) scale(0.73); }
  80% {
    transform: translateX(14vw) translateY(-18vh) scale(0.51); }
  84% {
    transform: translateX(20vw) translateY(43vh) scale(0.7); }
  88% {
    transform: translateX(-23vw) translateY(8vh) scale(0.69); }
  92% {
    transform: translateX(30vw) translateY(-2vh) scale(0.99); }
  96% {
    transform: translateX(48vw) translateY(-8vh) scale(0.79); }
  100% {
    transform: translateX(15vw) translateY(50vh) scale(0.99); } }

.firefly:nth-child(10) {
  animation-name: move10; }
  .firefly:nth-child(10)::before {
    animation-duration: 13s; }
  .firefly:nth-child(10)::after {
    animation-duration: 13s, 5600ms;
    animation-delay: 0ms, 6015ms; }

@keyframes move10 {
  0% {
    transform: translateX(-18vw) translateY(-41vh) scale(0.32); }
  3.57143% {
    transform: translateX(-39vw) translateY(7vh) scale(0.89); }
  7.14286% {
    transform: translateX(-24vw) translateY(-28vh) scale(0.77); }
  10.71429% {
    transform: translateX(44vw) translateY(-20vh) scale(0.96); }
  14.28571% {
    transform: translateX(-34vw) translateY(-15vh) scale(0.96); }
  17.85714% {
    transform: translateX(2vw) translateY(50vh) scale(0.73); }
  21.42857% {
    transform: translateX(-11vw) translateY(22vh) scale(0.53); }
  25% {
    transform: translateX(-46vw) translateY(11vh) scale(0.94); }
  28.57143% {
    transform: translateX(0vw) translateY(18vh) scale(0.84); }
  32.14286% {
    transform: translateX(-43vw) translateY(-27vh) scale(0.63); }
  35.71429% {
    transform: translateX(-42vw) translateY(-3vh) scale(0.64); }
  39.28571% {
    transform: translateX(-26vw) translateY(-43vh) scale(0.36); }
  42.85714% {
    transform: translateX(-9vw) translateY(-22vh) scale(0.83); }
  46.42857% {
    transform: translateX(36vw) translateY(44vh) scale(0.74); }
  50% {
    transform: translateX(24vw) translateY(22vh) scale(0.61); }
  53.57143% {
    transform: translateX(-14vw) translateY(-39vh) scale(0.44); }
  57.14286% {
    transform: translateX(-23vw) translateY(34vh) scale(0.59); }
  60.71429% {
    transform: translateX(-28vw) translateY(24vh) scale(0.75); }
  64.28571% {
    transform: translateX(-18vw) translateY(42vh) scale(0.7); }
  67.85714% {
    transform: translateX(-47vw) translateY(5vh) scale(0.84); }
  71.42857% {
    transform: translateX(22vw) translateY(-6vh) scale(0.65); }
  75% {
    transform: translateX(9vw) translateY(36vh) scale(0.38); }
  78.57143% {
    transform: translateX(26vw) translateY(-22vh) scale(0.95); }
  82.14286% {
    transform: translateX(-23vw) translateY(47vh) scale(0.88); }
  85.71429% {
    transform: translateX(-33vw) translateY(22vh) scale(0.27); }
  89.28571% {
    transform: translateX(0vw) translateY(27vh) scale(0.48); }
  92.85714% {
    transform: translateX(-6vw) translateY(-39vh) scale(0.33); }
  96.42857% {
    transform: translateX(-40vw) translateY(16vh) scale(0.87); }
  100% {
    transform: translateX(-34vw) translateY(24vh) scale(0.83); } }

.firefly:nth-child(11) {
  animation-name: move11; }
  .firefly:nth-child(11)::before {
    animation-duration: 13s; }
  .firefly:nth-child(11)::after {
    animation-duration: 13s, 5159ms;
    animation-delay: 0ms, 3381ms; }

@keyframes move11 {
  0% {
    transform: translateX(20vw) translateY(-46vh) scale(0.55); }
  5.55556% {
    transform: translateX(26vw) translateY(-31vh) scale(0.47); }
  11.11111% {
    transform: translateX(-35vw) translateY(23vh) scale(0.72); }
  16.66667% {
    transform: translateX(8vw) translateY(-40vh) scale(0.42); }
  22.22222% {
    transform: translateX(-42vw) translateY(12vh) scale(0.69); }
  27.77778% {
    transform: translateX(35vw) translateY(23vh) scale(0.74); }
  33.33333% {
    transform: translateX(42vw) translateY(-23vh) scale(0.3); }
  38.88889% {
    transform: translateX(-21vw) translateY(46vh) scale(0.51); }
  44.44444% {
    transform: translateX(-31vw) translateY(32vh) scale(0.41); }
  50% {
    transform: translateX(-4vw) translateY(16vh) scale(0.72); }
  55.55556% {
    transform: translateX(35vw) translateY(-1vh) scale(0.84); }
  61.11111% {
    transform: translateX(-3vw) translateY(-6vh) scale(0.59); }
  66.66667% {
    transform: translateX(-21vw) translateY(23vh) scale(0.47); }
  72.22222% {
    transform: translateX(40vw) translateY(-16vh) scale(0.75); }
  77.77778% {
    transform: translateX(-4vw) translateY(-37vh) scale(0.6); }
  83.33333% {
    transform: translateX(-34vw) translateY(12vh) scale(0.8); }
  88.88889% {
    transform: translateX(-49vw) translateY(-36vh) scale(0.32); }
  94.44444% {
    transform: translateX(35vw) translateY(4vh) scale(0.68); }
  100% {
    transform: translateX(-15vw) translateY(-40vh) scale(0.51); } }

.firefly:nth-child(12) {
  animation-name: move12; }
  .firefly:nth-child(12)::before {
    animation-duration: 18s; }
  .firefly:nth-child(12)::after {
    animation-duration: 18s, 6720ms;
    animation-delay: 0ms, 2190ms; }

@keyframes move12 {
  0% {
    transform: translateX(0vw) translateY(38vh) scale(0.3); }
  3.57143% {
    transform: translateX(-14vw) translateY(-21vh) scale(0.71); }
  7.14286% {
    transform: translateX(8vw) translateY(-18vh) scale(0.95); }
  10.71429% {
    transform: translateX(16vw) translateY(33vh) scale(0.38); }
  14.28571% {
    transform: translateX(-25vw) translateY(24vh) scale(0.38); }
  17.85714% {
    transform: translateX(13vw) translateY(-2vh) scale(0.88); }
  21.42857% {
    transform: translateX(27vw) translateY(33vh) scale(0.3); }
  25% {
    transform: translateX(31vw) translateY(-15vh) scale(0.54); }
  28.57143% {
    transform: translateX(-6vw) translateY(17vh) scale(0.88); }
  32.14286% {
    transform: translateX(-37vw) translateY(5vh) scale(0.7); }
  35.71429% {
    transform: translateX(30vw) translateY(25vh) scale(0.95); }
  39.28571% {
    transform: translateX(-25vw) translateY(19vh) scale(0.87); }
  42.85714% {
    transform: translateX(24vw) translateY(43vh) scale(0.29); }
  46.42857% {
    transform: translateX(-41vw) translateY(8vh) scale(0.86); }
  50% {
    transform: translateX(-31vw) translateY(37vh) scale(0.8); }
  53.57143% {
    transform: translateX(-24vw) translateY(42vh) scale(0.36); }
  57.14286% {
    transform: translateX(42vw) translateY(-36vh) scale(0.91); }
  60.71429% {
    transform: translateX(-29vw) translateY(38vh) scale(0.93); }
  64.28571% {
    transform: translateX(-23vw) translateY(35vh) scale(0.9); }
  67.85714% {
    transform: translateX(-16vw) translateY(33vh) scale(0.29); }
  71.42857% {
    transform: translateX(21vw) translateY(10vh) scale(0.65); }
  75% {
    transform: translateX(42vw) translateY(-21vh) scale(0.78); }
  78.57143% {
    transform: translateX(-8vw) translateY(-44vh) scale(0.52); }
  82.14286% {
    transform: translateX(-23vw) translateY(9vh) scale(0.4); }
  85.71429% {
    transform: translateX(-2vw) translateY(28vh) scale(0.55); }
  89.28571% {
    transform: translateX(39vw) translateY(-28vh) scale(0.96); }
  92.85714% {
    transform: translateX(1vw) translateY(-47vh) scale(0.52); }
  96.42857% {
    transform: translateX(-7vw) translateY(-17vh) scale(0.27); }
  100% {
    transform: translateX(-17vw) translateY(49vh) scale(0.3); } }

.firefly:nth-child(13) {
  animation-name: move13; }
  .firefly:nth-child(13)::before {
    animation-duration: 17s; }
  .firefly:nth-child(13)::after {
    animation-duration: 17s, 6570ms;
    animation-delay: 0ms, 7513ms; }

@keyframes move13 {
  0% {
    transform: translateX(-44vw) translateY(-7vh) scale(0.44); }
  4.7619% {
    transform: translateX(-22vw) translateY(-44vh) scale(0.44); }
  9.52381% {
    transform: translateX(6vw) translateY(8vh) scale(0.38); }
  14.28571% {
    transform: translateX(37vw) translateY(12vh) scale(0.85); }
  19.04762% {
    transform: translateX(47vw) translateY(-8vh) scale(0.55); }
  23.80952% {
    transform: translateX(27vw) translateY(43vh) scale(0.38); }
  28.57143% {
    transform: translateX(35vw) translateY(1vh) scale(0.95); }
  33.33333% {
    transform: translateX(-5vw) translateY(-46vh) scale(0.36); }
  38.09524% {
    transform: translateX(-28vw) translateY(-3vh) scale(0.83); }
  42.85714% {
    transform: translateX(-26vw) translateY(-42vh) scale(0.47); }
  47.61905% {
    transform: translateX(-25vw) translateY(3vh) scale(0.66); }
  52.38095% {
    transform: translateX(12vw) translateY(41vh) scale(0.71); }
  57.14286% {
    transform: translateX(-41vw) translateY(15vh) scale(0.92); }
  61.90476% {
    transform: translateX(42vw) translateY(-20vh) scale(0.4); }
  66.66667% {
    transform: translateX(-8vw) translateY(-12vh) scale(0.61); }
  71.42857% {
    transform: translateX(16vw) translateY(7vh) scale(0.88); }
  76.19048% {
    transform: translateX(14vw) translateY(49vh) scale(0.36); }
  80.95238% {
    transform: translateX(-24vw) translateY(26vh) scale(0.91); }
  85.71429% {
    transform: translateX(15vw) translateY(19vh) scale(0.66); }
  90.47619% {
    transform: translateX(-1vw) translateY(-17vh) scale(0.74); }
  95.2381% {
    transform: translateX(43vw) translateY(-25vh) scale(0.83); }
  100% {
    transform: translateX(2vw) translateY(37vh) scale(0.5); } }

.firefly:nth-child(14) {
  animation-name: move14; }
  .firefly:nth-child(14)::before {
    animation-duration: 16s; }
  .firefly:nth-child(14)::after {
    animation-duration: 16s, 9010ms;
    animation-delay: 0ms, 8001ms; }

@keyframes move14 {
  0% {
    transform: translateX(-6vw) translateY(10vh) scale(0.5); }
  3.57143% {
    transform: translateX(-42vw) translateY(-42vh) scale(0.99); }
  7.14286% {
    transform: translateX(40vw) translateY(-39vh) scale(0.51); }
  10.71429% {
    transform: translateX(-34vw) translateY(31vh) scale(0.7); }
  14.28571% {
    transform: translateX(-13vw) translateY(41vh) scale(0.83); }
  17.85714% {
    transform: translateX(36vw) translateY(-46vh) scale(0.33); }
  21.42857% {
    transform: translateX(16vw) translateY(3vh) scale(0.92); }
  25% {
    transform: translateX(38vw) translateY(23vh) scale(0.84); }
  28.57143% {
    transform: translateX(44vw) translateY(-15vh) scale(0.71); }
  32.14286% {
    transform: translateX(-5vw) translateY(-48vh) scale(0.47); }
  35.71429% {
    transform: translateX(-13vw) translateY(-47vh) scale(0.29); }
  39.28571% {
    transform: translateX(42vw) translateY(-15vh) scale(0.77); }
  42.85714% {
    transform: translateX(23vw) translateY(-16vh) scale(0.96); }
  46.42857% {
    transform: translateX(30vw) translateY(43vh) scale(0.56); }
  50% {
    transform: translateX(41vw) translateY(-38vh) scale(0.39); }
  53.57143% {
    transform: translateX(-7vw) translateY(-27vh) scale(0.94); }
  57.14286% {
    transform: translateX(-22vw) translateY(1vh) scale(0.76); }
  60.71429% {
    transform: translateX(31vw) translateY(-37vh) scale(0.47); }
  64.28571% {
    transform: translateX(-6vw) translateY(35vh) scale(0.56); }
  67.85714% {
    transform: translateX(-13vw) translateY(1vh) scale(0.37); }
  71.42857% {
    transform: translateX(42vw) translateY(-48vh) scale(0.26); }
  75% {
    transform: translateX(-47vw) translateY(34vh) scale(0.33); }
  78.57143% {
    transform: translateX(22vw) translateY(35vh) scale(0.43); }
  82.14286% {
    transform: translateX(-14vw) translateY(16vh) scale(0.72); }
  85.71429% {
    transform: translateX(42vw) translateY(-13vh) scale(0.98); }
  89.28571% {
    transform: translateX(13vw) translateY(-36vh) scale(0.54); }
  92.85714% {
    transform: translateX(-30vw) translateY(40vh) scale(0.82); }
  96.42857% {
    transform: translateX(-39vw) translateY(4vh) scale(0.92); }
  100% {
    transform: translateX(-2vw) translateY(-44vh) scale(0.76); } }

.firefly:nth-child(15) {
  animation-name: move15; }
  .firefly:nth-child(15)::before {
    animation-duration: 16s; }
  .firefly:nth-child(15)::after {
    animation-duration: 16s, 6255ms;
    animation-delay: 0ms, 510ms; }

@keyframes move15 {
  0% {
    transform: translateX(3vw) translateY(-23vh) scale(0.68); }
  4.16667% {
    transform: translateX(1vw) translateY(-49vh) scale(0.48); }
  8.33333% {
    transform: translateX(13vw) translateY(2vh) scale(0.71); }
  12.5% {
    transform: translateX(-42vw) translateY(-12vh) scale(0.69); }
  16.66667% {
    transform: translateX(28vw) translateY(11vh) scale(0.44); }
  20.83333% {
    transform: translateX(-27vw) translateY(-32vh) scale(0.65); }
  25% {
    transform: translateX(-41vw) translateY(10vh) scale(0.43); }
  29.16667% {
    transform: translateX(26vw) translateY(-23vh) scale(0.44); }
  33.33333% {
    transform: translateX(13vw) translateY(4vh) scale(0.99); }
  37.5% {
    transform: translateX(22vw) translateY(22vh) scale(0.72); }
  41.66667% {
    transform: translateX(-17vw) translateY(-13vh) scale(0.93); }
  45.83333% {
    transform: translateX(-7vw) translateY(32vh) scale(0.73); }
  50% {
    transform: translateX(-24vw) translateY(-9vh) scale(0.35); }
  54.16667% {
    transform: translateX(-37vw) translateY(-9vh) scale(0.3); }
  58.33333% {
    transform: translateX(42vw) translateY(-24vh) scale(1); }
  62.5% {
    transform: translateX(37vw) translateY(-3vh) scale(0.4); }
  66.66667% {
    transform: translateX(-34vw) translateY(0vh) scale(0.31); }
  70.83333% {
    transform: translateX(18vw) translateY(46vh) scale(0.4); }
  75% {
    transform: translateX(-14vw) translateY(-23vh) scale(0.71); }
  79.16667% {
    transform: translateX(3vw) translateY(-8vh) scale(0.93); }
  83.33333% {
    transform: translateX(25vw) translateY(41vh) scale(0.88); }
  87.5% {
    transform: translateX(46vw) translateY(-39vh) scale(0.46); }
  91.66667% {
    transform: translateX(18vw) translateY(45vh) scale(0.54); }
  95.83333% {
    transform: translateX(-41vw) translateY(-9vh) scale(0.57); }
  100% {
    transform: translateX(44vw) translateY(-8vh) scale(0.55); } }

@keyframes drift {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow; }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow; } }
`