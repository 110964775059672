import styled from "styled-components";
import listing_bg from '../../../assets/Home/Listing/listing_bg.jpg'

export const ListingContainer = styled.div`
  position: relative;
  background: url(${listing_bg}) no-repeat center / 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 752px;
  figure {
    width: 637px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: 450px;
    background-size: cover;
    figure {
      margin: 0 20px;
    }
  }
`