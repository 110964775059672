import styled from "styled-components";
import airdrop_bg from '../../assets/AirDrop/airdrop_bg.png'
import item_bg from '../../assets/AirDrop/item_bg.png'
import hover_mouse from '../../assets/Mouse/hover_mouse.png'

import button_bg from '../../assets/Button/button_bg.png'

export const AirDropContainer = styled.div`
  position: relative;
  min-height: calc(100vh - 76px);
  background: url(${airdrop_bg}) no-repeat center / cover;
  padding: 126px 10px 20px;
`
export const AirDropTitle = styled.h2`
  max-width: 488px;
  width: 100%;
  margin: 0 auto 30px;
  color: #FFF;
  text-align: center;
  text-shadow: 0px 0px 4px #000;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.8;
  img {
    display: block;
    max-width: 346px;
    width: 100%;
    margin: 0 auto;
  }
`
export const AirDropWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`
export const AirDropList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  & > li {
    max-width: 282px;
    width: 100%;
    padding: 25px 40px;
    min-height: 340px;
    background: url(${item_bg}) no-repeat center / 100% 100%;
  }
  @media screen and (max-width: 886px) {
    flex-wrap: wrap;
  }
`
export const AirDropTop = styled.div<{ tag?: number }>`
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 2px solid #E5E5E5;
  padding-bottom: 15px;
  margin-bottom: 15px;
  figure {
    width: 72px;
    height: 72px;
    border: 3px solid ${({ tag }) => (tag === 0 ? "#F79009" : tag === 1 ? "#EAECF0" : "#32D583")};
    border-radius: 13px;
    overflow: hidden;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`
export const AirDropTag = styled.div<{ tag?: number }>`
  display: flex;
  align-items: center;
  max-width: fit-content;
  gap: 5px;
  color: ${({ tag }) => (tag === 0 ? "#FF8A00" : tag === 1 ? "##667085" : "#12B76A")};
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  border-radius: 30px;
  padding: 5px 10px;
  margin-bottom: 10px;
  background: ${({ tag }) => (tag === 0 ? "#FFE2BF" : tag === 1 ? "#EAECF0" : "#A6F4C5")};
  span {
    width: 10px;
    height: 10px;
    background-color: ${({ tag }) => (tag === 0 ? "#F79009" : tag === 1 ? "#667085" : "#32D583")};
    border-radius: 50%;
  }
`
export const AirDropTitleItem = styled.div`
  h2 {
    color: #101828;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }
`
export const AirDropContent = styled.div`
  min-height: 170px;
  max-height: 170px;
  height: 100%;
  overflow-y: auto;
  border-bottom: 2px solid #E5E5E5;
  padding-bottom: 10px;
  margin-bottom: 10px;
`
export const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  font-weight: 600;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
export const AirDropAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: #FCFCFD;
  p {
    text-shadow: 0px 0px 2.8px rgba(255, 136, 26, 0.80);
    font-size: 15px;
    font-weight: 600;
  }
`
export const ViewAirDrop = styled.div`
  max-width: 140px;
  height: auto;
  margin: 0 auto;
  cursor: url(${hover_mouse}), pointer;
  button:disabled {
    color: #8b7488;
    cursor: no-drop ;
  }
  button {
    color: #000;
    position: relative;
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: center;
    width: 120px;
    padding: 20px 10px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    z-index: 3;
    transition: transform 0.25s;
    font-family: Saint-Regus;
    background-color: transparent;
    border: 0;
    cursor: url(${hover_mouse}), pointer;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      background: url(${button_bg}) no-repeat center / 100% 100%;
      width: 100%;
      height: 37px;
      margin-top: -20px;
      z-index: -1;
    }
    img {
      width: 32px;
      cursor: url(${hover_mouse}), pointer;
      margin-left: 10px;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
`