import { createContext, ReactNode, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import normal_mouse from '../../assets/Mouse/normal_mouse.png'

interface StoreContextValue {
  isDesktop: any;
  isTablet: any;
  isMobile: any;
  onClose: any;
  onOpen: any;
  isShowModal: any;
}
interface Props {
  children: ReactNode;
}
export const ContextProviderWrapper = createContext<StoreContextValue | null>(
  null
);
export const ContextProvider: React.FC<Props> = ({ children }: Props) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isTablet = useMediaQuery({ maxWidth: 1023, minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isShowModal, setIsShowModal] = useState(false);
  const [PopUp, setPopUp] = useState<ReactNode>(<></>);
  const onOpen = (Modal: ReactNode) => {
    setIsShowModal(true);
    setPopUp(Modal);
  };
  const onClose = () => {
    setIsShowModal(false);
    setPopUp(<></>);
  };

  useEffect(() => {
    if (isShowModal) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [isShowModal]);

  return (
    <ContextProviderWrapper.Provider
      value={{
        isDesktop,
        isTablet,
        isMobile,
        onClose,
        onOpen,
        isShowModal,
      }}
    >
      <ModalLayout isShowModal={isShowModal}>
        {(isShowModal) && (
          <ModalOverlay onClick={() => onClose()}>
            <div
              onClick={(e: any) => {
                // e.preventDefault();
                e.stopPropagation();
              }}
            >
              {PopUp}
            </div>
          </ModalOverlay>
        )}
      </ModalLayout>
      {children}
    </ContextProviderWrapper.Provider>
  );
};

export const ModalLayout = styled.div<{ isShowModal?: boolean }>`
  height: fit-content;
  overflow: hidden;
  cursor: url(${normal_mouse}), pointer;
`;
export const ModalOverlay = styled.div<{ lock?: boolean }>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  transform: translateX(-50%) translateY(-50%);
  z-index: 2000;
  pointer-events: ${({ lock }) => (lock ? "none" : "")};
  animation: 0.2s ease-in 0s 1 normal forwards running showPopup;
  @keyframes showPopup {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1080px) {
    background: rgba(0, 0, 0, .95);
  }
  @media only screen and (max-width: 743px) {
    padding: 70px 20px;
  }
`;