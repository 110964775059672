import styled from "styled-components";
import keyvisual_bg from '../../../assets/Home/Keyvisual/keyvisual_bg.jpg'
import bg_content from '../../../assets/Home/Keyvisual/bg_content.png'

export const KeyvisualContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 76px;
  padding-bottom: 20px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url(${keyvisual_bg}) no-repeat center / 100% 100%;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  @media screen and (max-width: 1023px) {
    position: relative;
    &::before {
      background-size: cover;
    }
  }
`;
export const KeyvisualWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  figure {
    width: 350px;
    margin: 0 auto -20px;
    img {
      width: 100%;
      height: auto;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0 10px;
  }
`
export const KeyvisualInner = styled.div`
  position: sticky;
  top: 0;
  @media screen and (max-width: 1023px) {
    position: relative;
  }
`
export const KeyvisualContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 402px;
  min-height: 162px;
  padding: 40px 60px;
  color: #FFF;
  text-align: center;
  background: url(${bg_content}) no-repeat center / 100% auto;
  p {
    text-shadow: 0px 0px 3.071px rgba(0, 0, 0, 0.68);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.8;
  }
  span {
    color: #000;
    font-size: 23px;
  }
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
  @media (min-width: 320px) and (max-width: 374px) {
    font-size: 10px;
  }
`