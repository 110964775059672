import { ButtonCommon } from "../../Layout/styled"
import { PreLoaderContainer, PreLoaderTop, PreLoaderWrapper, WithoutSound } from "./styled"
import top from '../../assets/Home/Keyvisual/keyvisual_bonkki.png'
import { useEffect, useState } from "react"

const PreLoader = ({ handlePlay }: any) => {
  const [preLoaderShow, setPreLoaderShow] = useState(true)

  useEffect(() => {
    if (preLoaderShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.overflowX = "hidden";
    }
  }, [preLoaderShow]);

  const handleOffPreloader = () => {
    setPreLoaderShow(false)
  }

  return (
    <PreLoaderContainer style={{
      opacity: preLoaderShow ? "1" : "0",
      visibility: preLoaderShow ? "visible" : "hidden"
    }}>
      <PreLoaderWrapper>
        <PreLoaderTop>
          <img src={top} alt="img" />
        </PreLoaderTop>
        <ButtonCommon width="260px" onClick={() => {
          handleOffPreloader();
          handlePlay()
        }}>
          <button style={{color: '#000'}}>
            ENTER THE JOURNEY
          </button>
        </ButtonCommon>
        <WithoutSound onClick={() => {
          handleOffPreloader()
        }}>
          Enter without sound
        </WithoutSound>
      </PreLoaderWrapper>
    </PreLoaderContainer>
  )
}

export default PreLoader