const CrackingEgg = () => {
  return (
    <svg
      width="200px"
      height="40px"
      viewBox="0 0 200 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id="eggsvg"
    >
      <title>cracking-egg</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M60,0 L60,0 L60,-1.42108547e-14 C93.137085,-2.02980385e-14 120,26.862915 120,60 L120,149 L0,149 L0,60 L0,60 C-4.05812251e-15,26.862915 26.862915,6.08718376e-15 60,0 Z"
          id="path-1"
        ></path>
        <path
          d="M15.5541748,21.9318557 C21.0770223,21.9318557 25.5541748,17.4547032 25.5541748,11.9318557 C25.5541748,6.40900816 21.0770223,1.93185566 15.5541748,1.93185566 C11.8722765,1.93185566 7.20560981,5.265189 1.55417481,11.9318557 C7.20560981,18.5985223 11.8722765,21.9318557 15.5541748,21.9318557 Z"
          id="path-3"
        ></path>
        <path
          d="M15.5541748,21.9318557 C21.0770223,21.9318557 25.5541748,17.4547032 25.5541748,11.9318557 C25.5541748,6.40900816 21.0770223,1.93185566 15.5541748,1.93185566 C11.8722765,1.93185566 7.20560981,5.265189 1.55417481,11.9318557 C7.20560981,18.5985223 11.8722765,21.9318557 15.5541748,21.9318557 Z"
          id="path-5"
        ></path>
        <polygon
          id="path-7"
          points="0.84833866 129.814433 10.8317441 122.204014 21.4153298 125.05071 44.0224901 110.424506 67 119.384873 90.1922136 103 104.932829 126.681536 121.114617 113.719006 144.555978 119.840768 176.640144 114.574736 196.895396 129.814433 212.987106 126.681536 212.987106 248.149059 5.16529475 248.149059"
        ></polygon>
        <polygon
          id="path-9"
          points="0.84833866 140.814433 10.8317441 133.204014 21.4153298 136.05071 44.0224901 121.424506 67 130.384873 90.1922136 114 104.932829 137.681536 121.114617 124.719006 144.555978 130.840768 176.640144 125.574736 196.895396 140.814433 212.987106 137.681536 212.987106 0.0995080672 1.7846937 0.0995080672"
        ></polygon>
        <rect id="path-11" x="" y="0" width="" height="34"></rect>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="crack" transform="translate(75.000000, 0.000000)">
          <mask id="mask-12" fill="white">
            <use xlinkHref="#path-11"></use>
          </mask>
          <g id="crack-mask"></g>
          <polyline
            id="crack-full"
            stroke="rgba(255, 255, 255,.7)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            mask="url(#mask-12)"
            points="-74.1516613 31.8144335 -64.1682559 24.2040144 -53.5846702 27.0507105 -30.9775099 12.4245057 -8 21.3848725 15.1922136 5 29.9328292 28.6815357 46.1146168 15.7190065 69.555978 21.8407678 101.640144 16.5747357 121.895396 31.8144335 137.987106 28.6815357"
          ></polyline>
        </g>
      </g>
    </svg>
  );
};

export default CrackingEgg;
