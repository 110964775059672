import Typewriter from "typewriter-effect";
import {
  ButtonBuy,
  ContentRow,
  HistoryContent,
  HistoryInfo,
  HistoryList,
  HistoryTime,
  HistoryTitle,
  HistoryTitleInner,
  HistoryTop,
  NftsButton,
  NftsComming,
  NftsContainer,
  NftsEgg,
  NftsFirst,
  NftsHistory,
  NftsInner,
  NftsOpen,
  NftsSecond,
  NftsTable,
  NftsTableContent,
  NftsTableDino,
  NftsTableInner,
  NftsTableTitle,
  NftsTableTop,
  NftsTabletCount,
  NftsTitle,
  NftsWrapper,
  OpenWrapper,
} from "./styled";
import button_buy from "../../assets/Nfts/button_buy.png";
import title from "../../assets/Nfts/title.png";
import egg from "../../assets/Nfts/egg.png";
import title_history from "../../assets/Nfts/history_title.png";
import nft_pic from "../../assets/Nfts/nft_pic.png";
import Trexy from "../../assets/Nfts/Trexy.png";
import Rapto from "../../assets/Nfts/Rapto.png";
import Spino from "../../assets/Nfts/Spino.png";
import nft_button from "../../assets/Nfts/nft_button.png";
import { useEffect, useState } from "react";
import { ButtonDefault } from "../../Layout/styled";
import CountDown from "./Countdown";
import Loading from "./../../components/Loading";
// import { AbiItem } from "web3-utils";
import table_header from "../../assets/Nfts/table_header.png";
import table_icon from "../../assets/Nfts/table_icon.png";
import table_dino from "../../assets/Nfts/table_dino.png";
import abiMintNFT from "../../constants/abi/abiMintNFT.json";
import { MINT_NFT_ADDRESS } from "../../constants/index";
import { notiError, notiSuccess, notiWarning } from "../../utils/notication";
import { formatAddress } from "../../utils/formats";
import { convertFromWei, convertToWei } from "../../utils/convertNumber";
import { useAccount, useNetwork } from "wagmi";
import Web3 from "web3";
import { AbiItem } from "web3-utils";

import res from "./res.json";
import CrackingEgg from "./EggCrack";

declare const window: Window & typeof globalThis & { ethereum: any };
const Nfts = () => {
  const { address }: any = useAccount();
  const { chain }: any = useNetwork();
  const web3 = new Web3(window.ethereum);
  const [historyData, setHistoryData] = useState<any>("");
  const [txh, setTxh] = useState<any>();
  const [buyAction, setBuyAction] = useState(false);
  const [eggHidden, setEggHidden] = useState(false);
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [minted, setMinted] = useState<any>("");
  const workChain = 56;


  const getAmountMinted = async () => {
    const contractMint: any = new web3.eth.Contract(
      abiMintNFT as unknown as AbiItem as AbiItem,
      MINT_NFT_ADDRESS[chain?.id]
    );
    const amount = await contractMint.methods
      .getAmountRemainingBoxPhases2()
      .call();
    setMinted(amount);
  };

  const handleMintNFT = async () => {
    try {
      setLoading(true);
      setEggHidden(false);
      setBuyAction(false);
      setPreview(false);
      const contractMint: any = new web3.eth.Contract(
        abiMintNFT as unknown as AbiItem as AbiItem,
        MINT_NFT_ADDRESS[chain?.id]
      );
      const gasPrice = await web3.eth.getGasPrice();
      const feeBNB = await contractMint.methods.getPriceBoxPhase2().call();
      const balance = await web3.eth.getBalance(address);
      const feeFromWei = convertFromWei(feeBNB, 18);
      const balanceBNB = convertFromWei(balance, 18);
      if (Number(feeFromWei) < Number(balanceBNB)) {
        await contractMint.methods
          .buyBox("1")
          .send({
            from: address,
            value: feeBNB,
            gasPrice: web3.utils.toHex(String(gasPrice)),
          })
          .on("transactionHash", (tx: any) => {
            if (tx) {
              setBuyAction(true);
              setPreview(true);
            }
          })
          .then((res: any) => {
            if (res?.status) {
              setHistoryData(res?.events?.BuyBox?.returnValues);
              setTxh(res?.transactionHash);
            }
            getAmountMinted();
            setLoading(false);
            setEggHidden(true);
            notiSuccess("Mint Successfully", 5);
          });
      } else {
        setLoading(false);
        setBuyAction(false);
        setPreview(false);
        setEggHidden(false);
        notiError('Balance is not enough', 5);
      }
    } catch (error: any) {
      console.error("error MintNFT", error);
      setLoading(false);
      setBuyAction(false);
      setPreview(false);
      setEggHidden(false);
      notiError(error?.message, 10);
    }
  };

  const NFT = (ipfs: any) => {
    switch (ipfs) {
      case "ipfs://bafkreiewgcdysfegkpqysokjg6d4rylkco6hoilu6kut25ra66ww632l6y":
        return <img src={Trexy} alt="R3" />;
      case "ipfs://bafkreidqpqvb74zcfbkxbmy6ekvqjqlpjjhl4dbohnc474mjxp5qmarvp4":
        return <img src={Spino} alt="R2" />;
      case "ipfs://bafkreib62youwxuhry4m7fg5i3vwazdfchznciloyipuc3k3ajercjj2jq":
        return <img src={Rapto} alt="R1" />;
      default:
        return <img src={Rapto} alt="R0" />;
    }
  };

  function formatTimestamp(timestamp: any) {
    const date = new Date(timestamp * 1000);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate().toString();
    return `${hours}:${minutes} ${month} ${day}th`;
  }
  useEffect(() => {
    if (chain?.id === workChain && address) {
      getAmountMinted();
    }
  }, [minted, chain?.id, address]);
  // console.log("chain?.id", chain?.id);
  // console.log("address", address);
  return (
    <NftsContainer className={eggHidden ? "effect" : ""}>
      <NftsWrapper>
        <NftsInner>
          <NftsTableInner
            style={{
              opacity: preview ? "0" : "1",
              visibility: preview ? "hidden" : "visible",
            }}
          >
            <NftsTableTitle>
              <img src={table_header} alt="header" />
            </NftsTableTitle>
            <NftsTable>
              <HistoryContent>
                <NftsTableTop>
                  <img src={table_icon} alt="icon" />
                  <div>
                    <NftsTableTitle>Cracking Egg</NftsTableTitle>
                    <p>
                      All 3 rarity R1, R2, R3 will appear randomly, all mint NFT
                      users will receive airdrops after mint NFT ends and can
                      buy token pre-sale .
                    </p>
                  </div>
                </NftsTableTop>
                <NftsTabletCount>
                  <p>
                    <Typewriter
                      options={{
                        strings: ["Upcoming..."],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </p>

                  {/* <p>START IN:</p> */}
                  {/* <CountDown /> */}
                </NftsTabletCount>
                <NftsTableDino>
                  <img src={table_dino} alt="dino" />
                  <p>139</p>
                </NftsTableDino>
                <NftsTableContent>
                  <ContentRow>
                    <p>Total Supply</p>
                    <p>139 NFT</p>
                  </ContentRow>
                  <ContentRow>
                    <p>Minted</p>
                    <p>{Number(139) - Number(minted)} NFT</p>
                  </ContentRow>
                  <ContentRow>
                    <p>Price</p>
                    <p>0.8 BNB / NFT</p>
                  </ContentRow>
                </NftsTableContent>
                <ButtonDefault width="174px" style={{ marginTop: "20px" }}>
                  {chain?.id === workChain ? (
                    <button
                    disabled
                      // disabled={
                      //   loading ||
                      //   !address ||
                      //   chain?.id !== workChain ||
                      //   Number(minted) === 0
                      // }
                      // onClick={handleMintNFT}
                    >
                      <Loading status={loading} content="Mint" />
                    </button>
                  ) : (
                    <button disabled>Switch Chain</button>
                  )}
                </ButtonDefault>
              </HistoryContent>
            </NftsTable>
          </NftsTableInner>
          <NftsFirst
            style={{
              opacity: preview ? "1" : "0",
              visibility: preview ? "visible" : "hidden",
            }}
          >
            <NftsTitle className={buyAction ? "hidden" : ""}>
              <span>
                <img src={title} alt="title" />
              </span>
            </NftsTitle>
            <NftsEgg
              style={{
                opacity: eggHidden ? "0" : "1",
                visibility: eggHidden ? "hidden" : "visible",
              }}
              className={buyAction ? "action" : ""}
            >
              <img src={egg} alt="egg" />
              <CrackingEgg />
            </NftsEgg>
          </NftsFirst>
          <NftsSecond
            style={{
              opacity: eggHidden ? "1" : "0",
              visibility: eggHidden ? "visible" : "hidden",
            }}
          >
            <NftsHistory>
              <HistoryTitle>
                <img src={title_history} alt="title" />
              </HistoryTitle>
              <HistoryContent>
                <HistoryTop>
                  <HistoryTitleInner>NFT Cracking Egg</HistoryTitleInner>
                  <p>Successfully cracking the Egg. Congratulations!</p>
                </HistoryTop>
                <HistoryList>
                  <HistoryTitleInner>History</HistoryTitleInner>
                  <ul>
                    <li>
                      <HistoryTime>
                        {formatTimestamp(historyData?.time)}
                      </HistoryTime>
                      <HistoryInfo>
                        By: {formatAddress(historyData?.buyer, 6, 4)}
                      </HistoryInfo>
                      <HistoryInfo>TokenId: {historyData?.tokenId}</HistoryInfo>
                      <HistoryInfo>Txh: {formatAddress(txh, 4, 4)}</HistoryInfo>
                    </li>
                  </ul>
                </HistoryList>
              </HistoryContent>
            </NftsHistory>
            <NftsOpen>
              <OpenWrapper>
                <figure>{NFT(historyData?.tokenURI)}</figure>

                <ButtonDefault width="174px">
                  {chain?.id === workChain ? (
                    <button
                      disabled={
                        loading ||
                        !address ||
                        chain?.id !== workChain ||
                        Number(minted) === 0
                      }
                      onClick={handleMintNFT}
                    >
                      <Loading status={loading} content="Buy Again" />
                    </button>
                  ) : (
                    <button disabled>Switch Chain</button>
                  )}
                </ButtonDefault>
              </OpenWrapper>
            </NftsOpen>
          </NftsSecond>
        </NftsInner>
      </NftsWrapper>
    </NftsContainer>
  );
};

export default Nfts;
