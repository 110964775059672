
import React , {useEffect, useState} from 'react';
import {
  AirDropAction,
  AirDropContainer,
  AirDropContent,
  AirDropList,
  AirDropTag,
  AirDropTitle,
  AirDropTitleItem,
  AirDropTop,
  AirDropWrapper,
  ContentRow,
  ViewAirDrop,
} from "./styled";
import title from "../../assets/AirDrop/title.png";
import item_img from "../../assets/AirDrop/item_img.jpg";
import view from "../../assets/AirDrop/view_airdrop.png";
import Countdown from "../../utils/countDown";

const AirDrop = () => {
  const currentTime = Math.floor(Date.now() / 1000);

  const airTime = (startTime: any, endTime: any) => {
    if (currentTime < startTime) {
      return (
        <>
          <p>Starts in</p>
          <Countdown timestamp={startTime} />
        </>
      );
    } else if (currentTime >= startTime && currentTime < endTime) {
      return (
        <>
          <p>End in</p>
          <Countdown timestamp={endTime} />
        </>
      );
    } else if (currentTime >= startTime && currentTime > endTime) {
      return (
        <>
          <p>End in</p>
          <span>Expired!</span>;
        </>
      );
    } else {
      return (
        <>
          <p>Start in</p>
          <span>TBA</span>;
        </>
      );
    }
  };
  const status = (startTime: any, endTime: any) => {
    if (currentTime < startTime) {
      return "Upcoming";
    } else if (currentTime >= startTime && currentTime < endTime) {
      return "Live";
    } else if (currentTime >= startTime && currentTime > endTime) {
      return "Expired";
    } else {
      return "Upcoming";
    }
  };
  const tag = (startTime: any, endTime: any) => {
    if (currentTime < startTime) {
      return 0;
    } else if (currentTime >= startTime && currentTime < endTime) {
      return 2;
    } else if (currentTime >= startTime && currentTime > endTime) {
      return 1;
    } else {
      return 0;
    }
  };
  return (
    <AirDropContainer>
      <AirDropWrapper>
        <AirDropTitle>
          <img src={title} alt="title" />
          For early contributors to the Bonkki project, your support means the
          world. You're the foundation of our vision, shaping the future of
          crypto. Your dedication fuels Bonkki's growth and innovation
        </AirDropTitle>
        <AirDropList>
          {airDropData.map((item:any, index) => {
            return (
              <li key={index}>
                <AirDropTop tag={tag(item.startTime, item.endTime)}>
                  <figure>
                    <img src={item.img} alt="img" />
                  </figure>
                  <AirDropTitleItem>
                    <AirDropTag tag={tag(item.startTime, item.endTime)}>
                      <span></span>
                    
                      {status(item.startTime, item.endTime)}
                    </AirDropTag>
                    <h2>{item.title}</h2>
                  </AirDropTitleItem>
                </AirDropTop>
                <AirDropContent>
                  <ContentRow>
                    <p>Token</p>
                    <p>{item.token}</p>
                  </ContentRow>
                  <ContentRow>
                    <p>Allocation</p>
                    <p>{item.allocation}</p>
                  </ContentRow>
                  <ContentRow>
                    <p>Total Token</p>
                    <p>{item.total}</p>
                  </ContentRow>
                  <ContentRow>
                    <p>Participants</p>
                    <p>{item.participants}</p>
                  </ContentRow>
                </AirDropContent>
                <AirDropAction>
                  {airTime(item.startTime, item.endTime)}
                </AirDropAction>
                <AirDropAction>
                  <ViewAirDrop>
                    <button disabled>Claim</button>
                  </ViewAirDrop>
                </AirDropAction>
              </li>
            );
          })}
        </AirDropList>
      </AirDropWrapper>
    </AirDropContainer>
  );
};

const airDropData = [
  {
    img: item_img,
    title: "JOIN event Community Airdrop",
    token: "BON",
    total: 0,
    participants: 0,
    allocation: 0,
    // startTime: 1703386388,
    // endTime: 1703386390,
  },
  {
    img: item_img,
    title: "JOIN buy NFT Round 2 Airdrop",
    token: "BON",
    total: 0,
    participants: 0,
    allocation: 0,
    // startTime: 1703387990,
    // endTime: 1703388190,
  },
  {
    img: item_img,
    title: "HOLDER - Token holder by month",
    token: "BON",
    total: 0,
    participants: 0,
    allocation: 0,
    // startTime: 1703386388,
    // endTime: 1703387990,
  },
];

export default AirDrop;
