import styled from "styled-components";

export const HomeContainer = styled.div``;
export const CombineWrapper = styled.div`
  background-color: #FB6514;
  position: relative;
`;
export const StickyInner = styled.div`
  position: sticky;
  top: 0;
`
export const StickyBlock = styled.div`
  min-height: 100vh;
`