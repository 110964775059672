import styled from "styled-components";
import nfts_bg from "../../assets/Nfts/nfts_bg.png";
import effect from "../../assets/Nfts/effect.png";
import pet from "../../assets/Nfts/pet.png";
import history_bg from "../../assets/Nfts/history_bg.png";
import history_item from "../../assets/Nfts/history_item.png";
import effect_light from "../../assets/Nfts/effect_light.png";
import hover_mouse from "../../assets/Mouse/hover_mouse.png";
import table_content_bg from "../../assets/Nfts/table_content_bg.png";
import { ButtonDefault } from "../../Layout/styled";

export const NftsSecond = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  left: -140px;
  transition: all 0.5s ease;
  @media (min-width: 767px) and (max-width: 1230px) {
    left: 75px;
  }
`;
export const NftsWrapper = styled.div`
  max-width: 900px;
  width: 100%;
  height: calc(100vh - 76px);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NftsContainer = styled.div`
  position: relative;
  min-height: calc(100vh - 76px);
  background: url(${nfts_bg}) no-repeat center / cover;
  padding: 100px 10px 20px;
  &.effect {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: url(${effect_light}) no-repeat center 90px / cover;
      width: 100%;
      height: 100%;
      animation: light 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s infinite;
    }
    @media screen and (max-width: 767px) {
      ${NftsSecond} {
        display: block;
        left: 0;
      }
      ${NftsWrapper} {
        height: unset;
      }
    }
  }
  @keyframes light {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const NftsInner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  &::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    background: url(${pet}) no-repeat center / 100% auto;
    width: 185px;
    height: 195px;
  }
  @media screen and (max-width: 520px) {
    &:before {
      width: 135px;
      height: 145px;
      bottom: -10px;
    }
  }
`;
export const NftsTitle = styled.h2`
  max-width: 214px;
  margin: 0 auto 50px;
  transition: all 0.5s ease;
  img {
    width: 100%;
    height: auto;
  }
  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
  span {
    display: block;
    margin-bottom: 10px;
  }
`;
export const NftsComming = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .Typewriter__wrapper {
    font-size: 25px;
  }
`;
export const ButtonBuy = styled.div`
  max-width: 174px;
  margin: 0 auto;
  height: auto;
  cursor: url(${hover_mouse}), pointer;
  img {
    width: 100%;
    height: auto;
  }
`;
export const NftsFirst = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;
export const NftsEgg = styled.div`
  position: relative;
  max-width: 142px;
  width: 100%;
  margin: 0 auto;
  height: auto;
  transition: all 0.25s;
  &::before {
    content: "";
    position: absolute;
    left: -10px;
    top: -25px;
    background: url(${effect}) no-repeat center / 100% auto;
    width: 162px;
    height: 223px;
    animation: firer 2s ease infinite;
  }
  svg {
    position: absolute;
    top: 42%;
    left: 0%;
    max-width: 142px;
    @keyframes cracking {
      0% {
        x: 0px;
        width: 0;
      }
      6% {
        x: 5px;
        width: 0;
      }
      11% {
        x: 5px;
        width: 50px;
      }
      16% {
        x: 5px;
        width: 50px;
      }
      20% {
        x: -20px;
        width: 100px;
      }
      25% {
        x: -20px;
        width: 100px;
      }
      40% {
        x: -80px;
        width: 260px;
      }
      100% {
        x: -80px;
        width: 260px;
      }
    }
  }
  &.action {
    #path-11 {
      animation: cracking 5s infinite;
    }
    animation: bounce 6s infinite;
    
    /* &::before {
      content: none;
    } */
  }
  img {
    width: 100%;
    height: auto;
  }
  @keyframes firer {
    0% {
      transform: translate(0);
      opacity: 0;
    }
    50% {
      transform: translateY(-10px);
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      transform: translate(0);
      opacity: 0;
    }
  }
  @keyframes bounce {
    0% {
      transform: rotate(0deg);
    }
    1% {
      transform: rotate(6deg);
    }
    2% {
      transform: rotate(0deg);
    }
    3% {
      transform: rotate(-6deg);
    }
    4% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(0deg);
    }
    11% {
      transform: rotate(6deg);
    }
    12% {
      transform: rotate(0deg);
    }
    13% {
      transform: rotate(-6deg);
    }
    14% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21% {
      transform: rotate(6deg);
    }
    22% {
      transform: rotate(0deg);
    }
    23% {
      transform: rotate(-6deg);
    }
    24% {
      transform: rotate(0deg);
    }
    40% {
      transform: translateY(-30px) rotate(0);
    }
  }
  @media screen and (max-width: 767px) {
    max-width: 122px;
    &::before {
      width: 142px;
      height: 203px;
    }
  }
`;
export const NftsHistory = styled.div`
  max-width: 300px;
  width: 100%;
  @media screen and (max-width: 767px) {
    margin: 0 auto 30px;
  }
`;
export const HistoryTitle = styled.div`
  max-width: 290px;
  width: 100%;
  height: auto;
  margin-left: auto;
  img {
    width: 100%;
    height: auto;
  }
`;
export const NftsOpen = styled.div`
  max-width: 234px;
  width: 100%;
  margin: 0 auto;
`;
export const OpenWrapper = styled.div`
  figure {
    width: 235px;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
export const NftsButton = styled.div`
  max-width: 177px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  cursor: url(${hover_mouse}), pointer;
  img {
    width: 100%;
    height: auto;
  }
`;
export const HistoryContent = styled.div`
  width: calc(100% - 50px);
  min-height: 300px;
  padding: 40px 30px;
  background: url(${history_bg}) no-repeat center / 100% 100%;
`;
export const HistoryTop = styled.div`
  margin-bottom: 20px;
  p {
    color: #fcfcfd;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.32px;
  }
`;
export const HistoryTitleInner = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;
export const HistoryList = styled.div`
  ul {
    max-height: 250px;
    overflow-y: auto;
    & > li {
      display: flex;
      flex-direction: column;
      width: calc(100% - 50px);
      padding: 15px 25px;
      background: url(${history_item}) no-repeat center / 100% 100%;
      margin-bottom: 5px;
    }
  }
`;
export const HistoryTime = styled.div`
  color: #101828;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.32px;
`;
export const HistoryInfo = styled.div`
  color: #101828;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
`;
export const NftsTableInner = styled.div`
  position: absolute;
  top: -60px;
  max-width: 340px;
  width: 100%;
  ${HistoryContent} {
    padding-top: 30px;
  }
`;
export const NftsTable = styled.div`
  width: 100%;
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 50;
  ${HistoryContent} {
    display: flex;
    flex-direction: column;
  }
  ${ButtonDefault} {
    margin-top: auto;
  }
`;
export const NftsTableTitle = styled.div`
  img {
    width: 100%;
  }
  color: #ffebdc;
  font-size: 21px;
  font-weight: 600;
`;
export const NftsTableTop = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  img {
    width: 46px;
    height: 38px;
  }
  p {
    color: #463464;
    font-size: 11px;
    font-weight: 600;
  }
`;
export const NftsTabletCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  & > p {
    color: #ffebdc;
    font-size: 17px;
    font-weight: 600;
  }
`;
export const NftsTableDino = styled.div`
  display: flex;
  align-items: center;
  mix-blend-mode: luminosity;
  max-width: 190px;
  width: 100%;
  margin: -5px 10px 10px;
  gap: 5px;
  & > p {
    margin-top: auto;
    color: #ffebdc;
    font-size: 21px;
    font-weight: 600;
    padding-bottom: 5px;
  }
  img {
    width: 100%;
    height: auto;
  }
`;
export const NftsTableContent = styled.div`
  background: url(${table_content_bg}) no-repeat center / 100% 100%;
  padding: 25px 45px;
`;
export const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > p {
    color: #664071;
    font-size: 14px;
    font-weight: 700;
  }
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  &:last-child {
    & > p {
      color: #134367;
      font-size: 16px;
    }
  }
`;
