import styled from "styled-components";
import button_bg from '../assets/Button/button_bg.png'
import leaf_right from '../assets/Home/Roadmap/leaf_right.png'
import leaf_left from '../assets/Home/Roadmap/leaf_left.png'
import hover_mouse from '../assets/Mouse/hover_mouse.png'

export const ButtonCommon = styled.div<{
  width?: string;
}>`
  position: relative;
  width: ${({ width }) => (width)};
  transition: all .5s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: url(${hover_mouse}), pointer;
  button {
    position: relative;
    align-items: center;
    display: flex;
    font-size: 18px;
    justify-content: center;
    width: ${({ width }) => (width)};
    padding: 0 10px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    z-index: 3;
    transition: transform 0.25s;
    font-family: Saint-Regus;
    background-color: transparent;
    border: 0;
    cursor: url(${hover_mouse}), pointer;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      background: url(${button_bg}) no-repeat center / 100% 100%;
      width: 100%;
      height: 53px;
      margin-top: -27px;
      z-index: -1;
    }
    img {
      width: 32px;
      cursor: url(${hover_mouse}), pointer;
      margin-left: 10px;
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    z-index: 0;
    transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    opacity: 0;
  }
  &::before {
    background-image: url(${leaf_left});
    width: 53px;
    height: 62px;
    left: 10px;
    top: -10px;
    transform: translate(0) rotate(45deg);
  }
  &::after {
    background-image: url(${leaf_right});
    width: 44px;
    height: 60px;
    right: 20px;
    bottom: -10px;
    transform: translate(0) rotate(45deg);
  }
  &:hover {
    filter: drop-shadow(0px 0px 23.7px rgba(255, 161, 20, 0.7));
    /* transform: scale(1.15); */
    &::before,
    &::after {
      opacity: 1;
    }
    &::before {
      transform: translate(-30px, -25px) rotate(0);
    }
    &::after {
      transform: translate(35px, 5px) rotate(0);
    }
  }
 
`;
export const ButtonDefault = styled.div<{
  width?: string;
  padding?: string;
  disabled?: boolean;
}>`
pointer-events: ${({ disabled }) => (disabled && ("none"))};
  position: relative;
  margin: 0 auto;
  width: ${({ width }) => (width)};
  padding: ${({ padding }) => (padding? padding : '10px 0')};
  transition: all .5s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: url(${hover_mouse}), pointer;
  button:disabled {
    color: #8b7488;
    cursor: no-drop ;
  }
  button {
    position: relative;
    align-items: center;
    color: #000;
    display: flex;
    font-size: 18px;
    justify-content: center;
    width: ${({ width }) => (width)};
    padding: 0 10px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    z-index: 3;
    transition: transform 0.25s;
    font-family: Saint-Regus;
    background-color: transparent;
    border: 0;
    cursor: url(${hover_mouse}), pointer;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      background: url(${button_bg}) no-repeat center / 100% 100%;
      width: 100%;
      height: 53px;
      margin-top: -27px;
      z-index: -1;
    }
    img {
      width: 32px;
      cursor: url(${hover_mouse}), pointer;
      margin-left: 10px;
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    z-index: 0;
    transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    opacity: 0;
  }
  
  &:hover {
    filter: drop-shadow(0px 0px 23.7px rgba(255, 161, 20, 0.7));
    /* transform: scale(1.15); */
    &::before,
    &::after {
      opacity: 1;
    }
    &::before {
      transform: translate(-30px, -25px) rotate(0);
    }
    &::after {
      transform: translate(35px, 5px) rotate(0);
    }
  }
 
`;

