import styled from "styled-components";
import bg from '../../../assets/Home/About/about_bg.png'

export const AboutContainer = styled.div`
  position: relative;
  padding: 100px 0;
  background: #22033C;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url(${bg}) no-repeat center / cover;
    width: 100%;
    height: 100%;
    mix-blend-mode: hard-light;
  }
  @media screen and (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export const AboutInner = styled.div`
`
export const MarqueeAbout = styled.div`
  transform: rotate(-2deg);
  margin: 0 -20px;
`;
export const AboutWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1084px;
  margin: 0 auto 70px;
`;
export const AboutTitle = styled.h2`
  text-align: center;
  margin-bottom: 15px;
  color: #FFF;
  font-size: 40px;
  font-weight: 600;
`;
export const AboutSubtitle = styled.p`
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  @media screen and (max-width:767px) {
    font-size: 16px;
  }
`;

export const AboutItem = styled.div`
  max-width: 130px;
  width: 100%;
  height: auto;
  margin: 0 14px;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width:767px) {
    max-width: 80px;
    margin-left: 7px;
    margin-right: 7px;
  }
`;