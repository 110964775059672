import { KeyvisualFireflies } from "./styled"

const Fireflies = () => {
  return (
    <KeyvisualFireflies>
      <div className="firefly"></div>
      <div className="firefly"></div>
      <div className="firefly"></div>
      <div className="firefly"></div>
      <div className="firefly"></div>
      <div className="firefly"></div>
      <div className="firefly"></div>
      <div className="firefly"></div>
      <div className="firefly"></div>
    </KeyvisualFireflies>
  )
}

export default Fireflies