import styled from "styled-components";
import bg from '../../assets/PreLoader/bg_preloader.png'
import hover_mouse from '../../assets/Mouse/hover_mouse.png'
import { ButtonCommon } from "../../Layout/styled";

export const PreLoaderContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #42083C;
  z-index: 9999;
  transition: all .25s ease;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${bg}) no-repeat center / cover;
    z-index: -1;
    mix-blend-mode: hard-light;
  }
  ${ButtonCommon} {
    margin-bottom: 40px;
  }
`
export const PreLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
`
export const PreLoaderTop = styled.div`
  max-width: 290px;
  width: 100%;
  margin-bottom: 60px;
  img {
    width: 100%;
    height: auto;
  }
`
export const WithoutSound = styled.p`
  color: #9C9C9C;
  text-align: center;
  font-size: 16px;
  cursor: url(${hover_mouse}), pointer;
`