import styled from "styled-components";
import bg from "../../assets/Ai/chat_bg.png";
import bg_chatbot from "../../assets/Ai/chat_bg_inner.png";
import decor from "../../assets/Ai/decor_01.png";

export const AiContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  min-height: 100vh;
  background: url(${bg}) no-repeat center / 100% 100%;
  @media screen and (max-width: 767px) {
    background-size: cover;
  }
`;
export const AiEmpty = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  .Typewriter__wrapper {
    font-size: 30px;
    color: #fff;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    .Typewriter__wrapper {
      font-size: 25px;
    }
  }
`;
export const AiHistory = styled.div`
  background-color: #fff;
  border-radius: 15px;
  max-width: 250px;
  width: 100%;
  padding: 15px;
  @media screen and (max-width: 1023px) {
    max-width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export const AiChatBot = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 120px 20px 50px;
  overflow: hidden;
  min-height: calc(100vh - 76px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media screen and (min-width: 767px) and (max-width: 1400px) {
    padding: 30px;
    margin-top: 60px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: calc(100% - 60px);
    padding: 30px;
  }
  @media screen and (max-width: 767px) {
    width: calc(100% - 20px);
    padding: 50px 10px 100px;
    margin-top: 76px;
    min-height: unset;
  }
`;
export const AiTitle = styled.h2`
  color: #000;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
`;
export const ChatBotWrapper = styled.div<{ message?: boolean }>`
  padding: ${({ message }) => (message ? "40px" : "80px 40px 40px")};
  background: url(${bg_chatbot}) no-repeat top / 100% 100%;
  max-width: 882px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  border: 2px solid #fff;
  border-radius: 20px;
  &:before {
    position: absolute;
    content: "";
    right: -200px;
    bottom: -50px;
    background: url(${decor}) no-repeat center / 100% 100%;
    width: 242px;
    height: 200px;
  }
  @media screen and (max-width: 1440px) {
    padding-top: 60px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding-top: 50px;
  }
  @media screen and (min-width: 768px) and (max-width: 1387px) {
    max-width: 740px;
    &:before {
      width: 202px;
      height: 150px;
      right: -160px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1165px) {
    &:before {
      bottom: -140px;
      right: -30px;
    }
  }
  @media screen and (max-width: 1023px) {
    min-height: auto;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 10px;
    border-radius: 20px;
    &:before {
      width: 150px;
      height: 121px;
      right: 0;
      bottom: -90px;
    }
  }
`;
export const ChatBotTitle = styled.h2`
  color: #000;
  text-align: center;
  font-family: Glicker-BlackExtra;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 10px;
  span {
    color: #97de00;
  }
  @media screen and (max-width: 767px) {
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 10px;
  }
`;
// export const ChatBotSubtitle = styled.p`
//   color: #000;
//   text-align: center;
//   font-family: Inter;
//   font-size: 18px;
//   font-weight: 600;
//   @media screen and (max-width: 767px) {
//     font-size: 15px;
//   }
// `;
export const ChatBotWindow = styled.div<{ message: boolean }>`
  overflow-y: auto;
  margin-bottom: 30px;
  padding: 0 10px;
  @media screen and (max-width: 1023px) {
    margin-bottom: 10px;
  }
  @media screen and (min-width: 768px) {
    height: ${({ message }) => (message ? "calc(100vh - 350px)" : "calc(100vh - 550px)")};
  }
  @media screen and (max-width: 767px) {
    padding: 0 5px;
    height: calc(100vh - 600px);
  }
`;
export const ChatBotHeader = styled.div`
  margin-bottom: 0;
  padding-top: 30px;
  @media screen and (max-width: 767px) {
    margin-bottom: 15px;
  }
`;
export const ChatBotLogo = styled.div`
  text-align: center;
  img {
    margin: 0 auto;
  }
`
export const HistoryList = styled.ul`
  margin-bottom: 20px;
  max-height: 500px;
  overflow-y: auto;
  @media screen and (max-width: 1023px) {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    max-height: 300px;
  }
`;
export const ChatBotEmpty = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  @media screen and (max-width: 767px) {
    font-size: 17px;
    text-align: center;
  }
`;
