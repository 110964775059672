import Marquee from "react-fast-marquee";
import { MarqueeContainer, MarqueeItem } from "./styled";
import marquee_1 from '../../assets/Marquee/marquee_1.png'
import marquee_2 from '../../assets/Marquee/marquee_2.png'

const MarqueeCommon = () => {
  return (
    <MarqueeContainer>
      <Marquee direction="right">
        {marqueeItems.map((item, index) => {
          return (
            <MarqueeItem key={index}>
              <img src={item.img} alt="logo" />
            </MarqueeItem>
          );
        })}
      </Marquee>
    </MarqueeContainer>
  )
}

const marqueeItems = [
  {
    img: marquee_1
  },
  {
    img: marquee_2
  },
  {
    img: marquee_1
  },
  {
    img: marquee_2
  },
  {
    img: marquee_1
  },
  {
    img: marquee_2
  },
]

export default MarqueeCommon