import { useEffect, useState } from "react";
import {
  AiChatBot,
  AiContainer,
  AiEmpty,
  ChatBotEmpty,
  ChatBotHeader,
  ChatBotLogo,
  ChatBotWindow,
  ChatBotWrapper,
} from "./styled";
import Message from "../../components/AI/Message";
import Input from "../../components/AI/Input";
import { MessageWait } from "../../components/AI/Message/styled";
import Typewriter from "typewriter-effect";
import { useAccount, useSignMessage } from "wagmi";

// Image
import logo from '../../assets/Footer/logo.png'

const Ai = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { address, isDisconnected } = useAccount();
  const { data: signMessageData, signMessage } = useSignMessage();

  const message = "To use AI Chat you need to confirm your signature";

  useEffect(() => {
    if (address && localStorage.getItem("verify") !== "verified") {
      signMessage({ message });
    }
  }, [address]);

  useEffect(() => {
    if (isDisconnected) {
      localStorage.setItem("verify", "not yet")
    }
  },[isDisconnected])

  const handleSubmit = async () => {
    const prompt = {
      role: "user",
      content: input,
    };

    setMessages([...messages, prompt]);

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: `Bearer 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`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [...messages, prompt],
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        const res = data.choices[0].message.content;
        setMessages((messages: any) => [
          ...messages,
          {
            role: "assistant",
            content: res,
          },
        ]);
        setInput("");
      });
  };

  return (
    <AiContainer>
      {!address ? (
        <>
          <AiChatBot>
            <ChatBotWrapper message={messages.length > 0}>
              {messages.length > 0 ? (
                <></>
              ) : (
                <ChatBotHeader>
                  <ChatBotLogo>
                    <img src={logo} width={160} alt="icon" loading="lazy" />
                  </ChatBotLogo>
                </ChatBotHeader>
              )}
              <ChatBotWindow message={messages.length > 0}>
                {messages.length > 0 ? (
                  <>
                    {messages.map((el: any, i: any) => {
                      return (
                        <Message
                          key={i}
                          role={el.role}
                          content={el.content}
                          isLoading={isLoading}
                        />
                      );
                    })}
                    {isLoading && (
                      <MessageWait>
                        <Typewriter
                          options={{
                            strings: ["Please wait LOLKEY a few minutes..."],
                            autoStart: true,
                            loop: true,
                          }}
                        />
                      </MessageWait>
                    )}
                  </>
                ) : (
                  <ChatBotEmpty>
                    {/* <p></p> */}
                  </ChatBotEmpty>
                )}
              </ChatBotWindow>
              <Input
                isLoading={isLoading}
                value={input}
                onChange={(e: any) => setInput(e.target.value)}
                onClick={input ? handleSubmit : undefined}
              />
            </ChatBotWrapper>
          </AiChatBot>
        </>
      ) : (
        <AiEmpty>
          <Typewriter
            options={{
              strings: ["Please connect your wallet..."],
              autoStart: true,
              loop: true,
            }}
          />
        </AiEmpty>
      )}
    </AiContainer>
  );
};

export default Ai;
