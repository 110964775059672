import MarqueeCommon from "../../../components/Marquee"
import { CardContainer, CardInner, CardWrapper } from "./styled"
import CardContent from "./Content"

const CardBlock = () => {
  return (
    <CardContainer>
      <CardInner>
        <CardWrapper>
          <MarqueeCommon />
          <CardContent />
        </CardWrapper>
      </CardInner>
    </CardContainer>
  )
}


export default CardBlock