import Marquee from "react-fast-marquee";
import {
  AboutContainer,
  AboutInner,
  AboutItem,
  AboutSubtitle,
  AboutTitle,
  AboutWrapper,
  MarqueeAbout,
} from "./styled";
import about_1 from "../../../assets/Home/About/about_1.png";
import about_2 from "../../../assets/Home/About/about_2.png";
import about_3 from "../../../assets/Home/About/about_3.png";
import about_4 from "../../../assets/Home/About/about_4.png";
import about_5 from "../../../assets/Home/About/about_5.png";
import about_6 from "../../../assets/Home/About/about_6.png";
import about_7 from "../../../assets/Home/About/about_7.png";
import about_8 from "../../../assets/Home/About/about_8.png";
import about_9 from "../../../assets/Home/About/about_9.png";
import about_10 from "../../../assets/Home/About/about_10.png";

const AboutBlock = () => {
  return (
    <AboutContainer id="about">
      <AboutInner>
        <div>
          <AboutWrapper>
            <AboutTitle>ABOUT LOLKEY</AboutTitle>
            <AboutSubtitle>
              Every day is an adventure for LOLKEY, the AI-powered meme king! He
              crashes crypto parties with punchlines so bad they make you LOL.
              But now, he is being hunted by everyone for the crown of the
              biggest meme!
            </AboutSubtitle>
          </AboutWrapper>
          <MarqueeAbout style={{ marginBottom: "25px" }}>
            <Marquee direction="right">
              {aboutFirst.map((item, index) => {
                return (
                  <AboutItem key={index}>
                    <img src={item.img} alt="logo" />
                  </AboutItem>
                );
              })}
            </Marquee>
          </MarqueeAbout>
          <MarqueeAbout>
            <Marquee direction="left">
              {aboutSecond.map((item, index) => {
                return (
                  <AboutItem key={index}>
                    <img src={item.img} alt="logo" />
                  </AboutItem>
                );
              })}
            </Marquee>
          </MarqueeAbout>
        </div>
      </AboutInner>
    </AboutContainer>
  );
};

const aboutFirst = [
  {
    img: about_1,
  },
  {
    img: about_2,
  },
  {
    img: about_3,
  },
  {
    img: about_4,
  },
  {
    img: about_5,
  },
  {
    img: about_6,
  },
  {
    img: about_7,
  },
  {
    img: about_8,
  },
  {
    img: about_9,
  },
  {
    img: about_10,
  },
  {
    img: about_1,
  },
  {
    img: about_2,
  },
  {
    img: about_3,
  },
  {
    img: about_4,
  },
  {
    img: about_5,
  },
  {
    img: about_6,
  },
  {
    img: about_7,
  },
  {
    img: about_8,
  },
  {
    img: about_9,
  },
  {
    img: about_10,
  },
];

const aboutSecond = [
  {
    img: about_1,
  },
  {
    img: about_6,
  },
  {
    img: about_4,
  },
  {
    img: about_3,
  },
  {
    img: about_7,
  },
  {
    img: about_9,
  },
  {
    img: about_2,
  },
  {
    img: about_10,
  },
  {
    img: about_8,
  },
  {
    img: about_5,
  },
  {
    img: about_1,
  },
  {
    img: about_6,
  },
  {
    img: about_4,
  },
  {
    img: about_3,
  },
  {
    img: about_7,
  },
  {
    img: about_9,
  },
  {
    img: about_2,
  },
  {
    img: about_10,
  },
  {
    img: about_8,
  },
  {
    img: about_5,
  },
];

export default AboutBlock;
