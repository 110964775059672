import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./page/Home";
import { AppContainer } from "./styled";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Ai from "./page/Ai";
import Nfts from "./page/Nfts";
import { ContextProvider } from "./components/Context";
import { Toaster } from "react-hot-toast";
import AirDrop from "./page/AirDrop";
import PreLoader from "./components/PreLoader";
import { useState } from "react";
import { WagmiConfig, createConfig, configureChains } from "wagmi";
import { bsc } from "wagmi/chains";

import { publicProvider } from "wagmi/providers/public";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc],
  [publicProvider()]
);
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "3a1eba421630b4028ff59600db61d251",
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  // Music
  const [audio] = useState(new Audio("../background_music.mp3"));
  const [isPlaying, setIsPlaying] = useState(false);
  document.addEventListener("contextmenu", (e) => {
    e.preventDefault();
  });
  document.addEventListener("keydown", function (e) {
    if (e.key === "F12" || (e.ctrlKey && e.shiftKey && e.key === "I")) {
      e.preventDefault();
    }
  });
  const handlePlay = () => {
    const playPromise = audio.play();
    if (playPromise) {
      playPromise
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
        });
    }
  };

  const handlePause = () => {
    audio.pause();
    audio.currentTime = 0;
    setIsPlaying(false);
  };

  return (
    <WagmiConfig config={config}>
      <ContextProvider>
        <Toaster position="top-right" reverseOrder={false} />
        <AppContainer>
          <PreLoader handlePlay={handlePlay} isPlaying={isPlaying} />
          <Header
            handlePlay={handlePlay}
            handlePause={handlePause}
            isPlaying={isPlaying}
          />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ai" element={<Ai />} />
            <Route path="/nfts" element={<Nfts />} />
            <Route path="/airdrops" element={<AirDrop />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </AppContainer>
      </ContextProvider>
    </WagmiConfig>
  );
};

export default App;
