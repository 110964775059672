import styled from "styled-components";
import { ButtonCommon } from "../../Layout/styled";
import hamburger_bg from '../../assets/Header/hamburger_bg.png'
import hover_mouse from '../../assets/Mouse/hover_mouse.png'

export const HeaderBody = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.5s ease;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(13px);
`;
export const HeaderContainer = styled.header`
  position: relative;
  width: calc(100% - 60px);
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 1440px;
  margin: 0 auto;
  @media screen and (max-width: 1166px) {
    width: calc(100% - 40px);
    padding: 10px 20px;
  }
  @media screen and (max-width: 1023px) {
    .sm {
      display: none;
    }
  }
`;
export const HeaderLogo = styled.div`
  width: 139px;
  height: auto;
  flex-shrink: 0;
  img {
    width: 100%;
    height: auto;
  }
  a {
    cursor: url(${hover_mouse}), pointer;
    display: block;
  }
`;
export const HeaderLinks = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  margin: 0 auto;
  & > li {
    a {
      cursor: url(${hover_mouse}), pointer;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      transition: text-shadow 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
      &:hover {
        text-shadow: 3px -1px 5px rgba(223, 223, 223,.5), -2px 1px 5px rgba(226, 226, 226, .5);
      }
    }
  }
  @media screen and (max-width: 1023px) {
    gap: 50px;
    margin-bottom: 70px;
    & > li {
      a {
        font-size: 20px;
      }
    }
  }
`;
export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  &.menu-fixed {
    position: fixed;
    justify-content: center;
    left: 0;
    top: 84px;
    background: rgba(0, 0, 0, .8);
    color: #fff;
    transition: transform 0.75s ease;
    transform: translateX(150%);
    z-index: -1;
    overflow-y: auto;
    ${HeaderLinks} {
      flex-direction: column;
    }
    ${ButtonCommon} {
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 767px) {
    &.menu-fixed {
      top: 70px;
    }
  }
`;
export const HeaderInner = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    width: 100%;
  }
  @media screen and (max-width: 1023px) {
    overflow-y: auto;
    height: calc(100vh - 220px);
    width: 100%;
    padding: 50px 0 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const ButtonHamburger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50px;
  height: 50px;
  background: url(${hamburger_bg}) no-repeat center / 100% auto;
`;
export const HeaderMusic = styled.div`
  width: 45px;
  height: 47px;
  cursor: url(${hover_mouse}), pointer;
  img {
    width: 100%;
    height: auto;
  }
`