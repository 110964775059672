import {
  ButtonHamburger,
  HeaderBody,
  HeaderContainer,
  HeaderContent,
  HeaderInner,
  HeaderLinks,
  HeaderLogo,
  HeaderMusic,
} from "./styled";
import logo from "../../assets/Header/logo.png";
import { Link } from "react-router-dom";
import { ButtonCommon } from "../../Layout/styled";
import { ContextProviderWrapper } from "../Context";
import { useContext, useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import on from "../../assets/Header/on.png";
import off from "../../assets/Header/off.png";

const Header = ({ handlePlay, handlePause, isPlaying }: any) => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [openHamburger, setOpenHamburger] = useState(false);
  const handleOpenHamburger = () => {
    setOpenHamburger(!openHamburger);
  };

  useEffect(() => {
    if (openHamburger) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [openHamburger]);

  return (
    <HeaderBody
      style={{
        background:
          !isDesktop && openHamburger ? "rgba(255, 255, 255, .5)" : "",
      }}
    >
      <HeaderContainer>
        <HeaderLogo>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </HeaderLogo>
        <HeaderContent
          style={
            !isDesktop && openHamburger ? { transform: "translateX(0)" } : {}
          }
          className={!isDesktop ? "menu-fixed" : ""}
        >
          <HeaderInner>
            <HeaderLinks>
              {linkData.map((item, index) => {
                if (item.scrollTo) {
                  return (
                    <li key={index}>
                      <HashLink
                        id="link-item"
                        smooth
                        to={item.href}
                        onClick={() => {
                          setOpenHamburger(false);
                        }}
                      >
                        {item.title}
                      </HashLink>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      {item.externalLink ? (
                        <>
                          <a target="_blank" rel="noreferrer" href={item.href}>
                            {item.title}
                          </a>
                        </>
                      ) : (
                        <>
                          <Link
                            onClick={() => setOpenHamburger(false)}
                            id="link-item"
                            to={item.href}
                          >
                            {item.title}
                          </Link>
                        </>
                      )}
                    </li>
                  );
                }
              })}
            </HeaderLinks>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              {isDesktop && (
                <HeaderMusic
                  onClick={() => {
                    isPlaying ? handlePause() : handlePlay();
                  }}
                >
                  <img src={isPlaying ? on : off} alt="music" />
                </HeaderMusic>
              )}

              <ButtonCommon
                width="174px"
                // onClick={() => {
                //   if (!isDesktop) {
                //     setOpenHamburger(false);
                //   }
                //   onOpen(<ConnectWalletModal />);
                // }}
              >
                <Link
                  to="https://springboard.pancakeswap.finance/bsc/token/0x71e0ea8546de0ee8579758e8ef0e2090f880f08c"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button style={{ color: "#000" }}>
                    {/* {address ? truncateAddress : "Connect Wallet"} */}
                    Buy $LOLKEY
                  </button>
                </Link>
              </ButtonCommon>
            </div>
          </HeaderInner>
        </HeaderContent>
        {!isDesktop && (
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <HeaderMusic
              onClick={() => {
                isPlaying ? handlePause() : handlePlay();
              }}
            >
              <img src={isPlaying ? on : off} alt="music" />
            </HeaderMusic>
            <ButtonHamburger
              className={openHamburger ? "active" : ""}
              onClick={() => {
                handleOpenHamburger();
              }}
            >
              <span></span>
              <span></span>
            </ButtonHamburger>
          </div>
        )}
      </HeaderContainer>
    </HeaderBody>
  );
};

const linkData = [
  {
    title: "Home",
    href: "/",
    scrollTo: false,
    externalLink: false,
  },
  {
    title: "About",
    href: "/#about",
    scrollTo: true,
    externalLink: false,
  },
  {
    title: "Ai Chat",
    href: "/ai",
    externalLink: false,
  },
  // {
  //   title: "NFTs",
  //   href: "/nfts",
  //   externalLink: false,
  // },
  // {
  //   title: "Airdrops",
  //   href: "/airdrops",
  //   externalLink: false,
  // },
  // {
  //   title: "Docs",
  //   href: "https://bonkki.gitbook.io/bonkki/",
  //   externalLink: true,
  // },
];

export default Header;
