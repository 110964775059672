import styled from "styled-components";

export const MarqueeContainer = styled.div`
  background-color: #000;
  padding: 18px 0;
`
export const MarqueeItem = styled.div`
  margin: 0 10px;
  max-width: 374px;
  height: auto;
  opacity: .6;
  img {
    width: 100%;
    height: auto;
  }
`