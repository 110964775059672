import styled from "styled-components";
import arrow_card from "../../../../assets/Home/Card/arrow_card.png";

export const CardContentContainer = styled.div`
  position: relative;
  z-index: 5;
  padding-top: 150px;
  &::before {
    content: "";
    position: absolute;
    background: url(${arrow_card}) no-repeat center / 100% auto;
    width: 335px;
    height: 155px;
    left: 0;
    top: 50%;
    margin-top: 100px;
  }
  figure {
    width: 540px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    &::before {
      content: none;
    }
    figure {
      width: auto;
      margin: 0 20px;
    }
  }
`