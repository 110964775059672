import {
  FooterContainer,
  FooterCopyRight,
  FooterLogo,
  FooterSocials,
  FooterWrapper,
} from "./styled";
import logo from "../../assets/Footer/logo.png";
import twitter from "../../assets/Footer/Twitter.svg";
import telegram from "../../assets/Footer/Telegram.svg";
import youtube from "../../assets/Footer/YouTube.svg";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterLogo>
          <img src={logo} alt="logo" />
        </FooterLogo>
        <FooterSocials>
          {socialList.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.href} target="_blank" rel="noreferrer">
                  <img src={item.img} alt="" />
                </a>
              </li>
            );
          })}
        </FooterSocials>
        {/* <FooterCopyRight>
          <small>
            Copyright © 2023 Lolkey, All rights reserved. Powered by Eliverse
          </small>
        </FooterCopyRight> */}
      </FooterWrapper>
    </FooterContainer>
  );
};

const socialList = [
  // {
  //   img: twitter,
  //   href: "https://twitter.com/BonkkiAi",
  // },
  // {
  //   img: reddit,
  //   href: "#",
  // },
  // {
  //   img: discord,
  //   href: "#",
  // },
  {
    img: telegram,
    href: "https://t.me/+jOyhMgrMVUc5ZDY1",
  },
  {
    img: telegram,
    href: "https://t.me/+CWZ_jUgg3ekxN2Q1",
  },
  // {
  //   img: youtube,
  //   href: "https://www.youtube.com/channel/UCn1iBNAccsw1yMZXHJtzTOw",
  // },
];

export default Footer;
