import { InputContainer } from "./styled";
import arrow from "../../../assets/Ai/arrow.svg";
import pause from "../../../assets/Ai/pause.svg";

export default function Input({ value, onChange, onClick, isLoading }: any) {
  return (
    <InputContainer>
      <textarea
        placeholder="Ask LOLKEY anything..."
        value={value}
        onChange={onChange}
        disabled={isLoading ? true : false}
      />
      <button
        style={{
          opacity: value ? "1" : ".4",
          pointerEvents:
            value && !isLoading ? "auto" : value && isLoading ? "none" : "none",
        }}
        onClick={onClick}
      >
        <img src={isLoading ? pause : arrow} alt="arrow" />
      </button>
    </InputContainer>
  );
}
