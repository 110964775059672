import {
  KeyvisualContainer,
  KeyvisualContent,
  KeyvisualInner,
  KeyvisualWrapper,
} from "./styled";
import bonkki from "../../../assets/Home/Keyvisual/keyvisual_bonkki.png";
import Fireflies from "./Fireflies";

const KeyvisualBlock = () => {
  return (
    <KeyvisualContainer>
      <Fireflies />
      <KeyvisualInner>
        <KeyvisualWrapper>
          <figure>
            <img src={bonkki} alt="lolkey" />
          </figure>
          <KeyvisualContent>
            <p>
              LOLKEY doesn’t stomp around looking for food; powered by AI, he’s
              the next big meme that everyone is hunting for
            </p>
          </KeyvisualContent>
        </KeyvisualWrapper>
      </KeyvisualInner>
    </KeyvisualContainer>
  );
};

export default KeyvisualBlock;
