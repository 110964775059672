import KeyvisualBlock from "./Keyvisual";
import RoadmapBlock from "./Roadmap";
import TokenomicBlock from "./Tokenomic";
import { CombineWrapper, HomeContainer, StickyBlock, StickyInner } from "./styled";
import AboutBlock from "./About";
import PartnerBlock from "./Partner";
import CommunityBlock from "./Community";
import CardBlock from "./Card";
import MarqueeCommon from "../../components/Marquee";
import ListingBlock from "./Listing";

const Home = () => {
  return (
    <HomeContainer>
      <KeyvisualBlock />
      <StickyBlock>
        <StickyInner>
          <AboutBlock />
          <CardBlock />
        </StickyInner>
      </StickyBlock>
      <ListingBlock />
      {/* <RoadmapBlock /> */}
      {/* <CombineWrapper>
        <MarqueeCommon />
        <TokenomicBlock />
        <PartnerBlock />
      </CombineWrapper>
      <CommunityBlock /> */}
    </HomeContainer>
  );
};

export default Home;
