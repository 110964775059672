import { ListingContainer } from './styled'
import listing_img from '../../../assets/Home/Listing/listing_img.png'

const ListingBlock = () => {
  return (
    <ListingContainer>
      <figure>
        <img src={listing_img} alt="icon" loading='lazy' />
      </figure>
    </ListingContainer>
  )
}

export default ListingBlock