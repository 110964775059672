import styled from "styled-components";
import hover_mouse from '../../assets/Mouse/hover_mouse.png'

export const FooterContainer = styled.div`
  position: relative;
  background-color: #000;
  padding: 30px;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 50px 30px;
  }
  @media screen and (max-width: 767px) {
    padding: 50px 10px;
  }
`;
export const FooterWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
export const FooterLogo = styled.div`
  width: 160px;
  height: auto;
  margin: 0 auto 20px;
  img {
    width: 100%;
    height: auto;
  }
`;
export const FooterSocials = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-bottom: 30px;
  & > li {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      width: 50px;
      height: 50px;
      border: 1px solid rgba(186, 186, 186, 0.50);
      border-radius: 13px;
      cursor: url(${hover_mouse}), pointer;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    & > li {
      a {
        padding: 5px;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    gap: 15px;
  }
  @media screen and (max-width: 767px) {
    gap: 10px;
  }
`;
export const FooterCopyRight = styled.div`
  text-align: center;
  small {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
  }
  @media screen and (max-width: 767px) {
    small {
      font-size: 18px;
    }
  }
`;
