import styled from "styled-components";
import bg from '../../../assets/Home/About/about_bg.png'
import before from '../../../assets/Home/Card/card_before.png'
import after from '../../../assets/Home/Card/card_after.png'
import decor from '../../../assets/Home/Card/decor_before.png'

export const CardContainer = styled.div`
  position: relative;
  background-color: #000;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
  }
  &::before {
    top: 0;
    background: url(${bg}) no-repeat center / cover;
    width: 100%;
    height: 100%;
    mix-blend-mode: hard-light;
  }
  &::after {
    background: url(${decor}) no-repeat center / 100% 100%;
    width: 100%;
    height: 177px;
    top: 15%;
    z-index: 0;
  }
  @media screen and (max-width: 1023px) {
    &::after {
      background-size: auto 100%;
    }
  }
`
export const CardInner = styled.div`
`
export const CardList = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  margin-top: 100px;
  z-index: 2;
  padding: 0 10px;
  & > li {
    position: relative;
    max-width: 332px;
    width: 100%;
    min-height: 360px;
    transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover {
      transform: scale(1.1);
    }
  }
  @media screen and (max-width: 1023px) {
    & > li {
      min-height: unset;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1023px) {
    gap: 20px;
    padding: 0 15px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 10px;
    display: block;
    margin-top: 20px;
    & > li {
      margin: 0 auto;
      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }
  }
`
export const CardItemWrapper = styled.div<{ color?: string }>`
  padding: 16px;
  background: ${({ color }) => (color)};
  border-radius: 26px;
`
export const CardItem = styled.div<{ color?: string, boxShadow?: string, border?: string, bgBefore?: string }>`
  position: relative;
  box-shadow: ${({ boxShadow }) => (boxShadow)};
  border-radius: 27px;
  transition: all .25s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;
  padding: 3px;
  &::before {
    content: "";
    top: 0;
    bottom: 0;
    left: -150px;
    right: -150px;
    background-image: linear-gradient(
    to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 40%,
      rgba(255, 255, 255, 0.3) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    transform-origin: center center;
    position: absolute;
    z-index: 10;
    animation-delay: 1s;
    animation-name: mask-ani;
    animation-duration: 7s;
    animation-iteration-count: infinite;
  }
  &::after {
    content: '';
    top: 0;
    bottom: 0;
    left: -150px;
    right: -150px;
    opacity: 0;
    background-color: #fff;
    transform-origin: center center;
    position: absolute;
    z-index: 1;
    animation-delay: 1s;
    animation-name: mask-border-ani;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    z-index: -1;
  }
  figure {
    width: 100%;
    height: 268px;
    border: 3px solid ${({ border }) => (border)};
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 14px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @keyframes mask-ani {
    0% {
      transform: rotate(-36deg) translate(-40px, -630px);
    }
    70%,
    100% {
      transform: rotate(-36deg) translate(-40px, 650px);
    }
  }
  @keyframes mask-border-ani {
    0% {
      opacity: 0.8;
      transform: rotate(-36deg) translate(-40px, -630px);
    }
    70%,
    100% {
      opacity: 0.8;
      transform: rotate(-36deg) translate(-40px, 650px);
    }
  }

  @media screen and (max-width: 1023px) {
    min-height: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    figure {
      height: 200px;
    }
  }
  @media screen and (max-width: 767px) {
    max-width: unset;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`
export const CardTitle = styled.h2`
  color: #101828;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
`
export const CardDesc = styled.p`
  color: #000;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
`
export const CardWrapper = styled.div`
  position: relative;
  padding-bottom: 150px;
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    bottom: 0;
    right: 0;
    background: url(${before}) no-repeat center / 100% auto;
    width: 470px;
    height: 342px;
  }
  &::after {
    bottom: 40px;
    left: 40px;
    background: url(${after}) no-repeat center / 100% auto;
    width: 341px;
    height: 54px;
  }
  @media screen and (min-width: 1024px) {
    min-height: 600px;
  }
  @media screen and (max-width: 1023px) {
    padding-bottom: 400px;
  }
  @media screen and (max-width: 767px) {
    &::after {
      content: none;
    }
  }
`